import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api1 from "./services/Api1";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();

  const {
    data,

    refetch,
  } = useQuery(["logindata"], () => Api1.get(`login/${email}/${password}`), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: false,
    select: (res) => {
      if (res.data.data == "Success") {
        navigate("/dashboard");
      }
      return res.data;
    },
  });

  useEffect(() => {
    if (data && data?.data != "Success") {
      alert("error");
    }
  }, [data]);
  console.log(data);
  return (
    <div>
      <div className="loginPage">
        <div className="loginHeader">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="loginLogo">
                  <a href="#">
                    <img src="logo.png" className="img-fluid" />
                  </a>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
        <div className="loginSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <div className="loginImg">
                  <figure>
                    <img src="wood.png" className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <div className="loginForm">
                  <h3>Sign In</h3>
                  <div className="form-group">
                    <label>Email:</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password:</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="loginBtn">
                    <input onClick={() => refetch()} value="Log in" />
                  </div>
                  <h6 style={{ color: "red" }}></h6>
                  <div className="loginCopyright">
                    <p>
                      Copyright &copy; Imed Store 2021. All Rights Reserved.{" "}
                      <span>
                        Powered By{" "}
                        <a
                          href="https://www.oriolinfotech.com/"
                          target="_blank"
                        >
                          Oriol Infotech Solutions
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
