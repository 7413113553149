import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Api2 from "../services/Api2";

const OrderDetail = () => {
  const [ono, setOno] = useState();
  const [status, setStatus] = useState();
  const [cancelStyle, setCancelStyle] = useState(true);
  const [item, setItem] = useState({});
  const [did, setDid] = useState();

  const { data: orderdetaildata, refetch: refetchorderdetaildata } = useQuery(
    ["orderdetail", ono],
    () => Api2.get(`/welcome/ordersDetail/${ono}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,

      select: (res) => res.data,
    }
  );

  const { data: orderstatus, refetch: refetchorderstatus } = useQuery(
    ["orderstatus"],
    () => Api2.get(`/welcome/changeStatus/${ono}/${status}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: deleteitem, refetch: refetchdelete } = useQuery(
    ["delete"],
    () => Api2.get(`/welcome/deleteItem/${did}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const handleLocalStorage = (tval) => {
    var tempItem = { ...item, order_status: tval };

    console.log(tempItem, tval);
    localStorage.setItem("item", JSON.stringify(tempItem));
  };

  var tval = null;
  const handleStatus = () => {
    if (status == "Accept") {
      setStatus("shipped");
      tval = "shipped";
    } else if (status == "shipped") {
      setStatus("delivered");
      tval = "delivered";
    }
    setTimeout(() => {
      refetchorderstatus();
      handleLocalStorage(tval);
    }, 1000);
  };
  const handleCancel = () => {
    setStatus("cancelled");
    tval = "cancelled";
    setCancelStyle(false);
    setTimeout(() => {
      refetchorderstatus();
      handleLocalStorage(tval);
    }, 1000);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("item"))) {
      var item = JSON.parse(localStorage.getItem("item"));
      setItem(item);
      setOno(item.order_no);
      if (item.order_status == "new") {
        setStatus("Accept");
      } else {
        setStatus(item.order_status);
      }
    }
  }, []);

  const handleDelete = (id) => {
    setDid(id);
    setTimeout(() => {
      refetchdelete();
      refetchorderdetaildata();
    }, 1000);
  };

  // console.log(status);
  return (
    <div className="wrapper" style={{ marginTop: "80px" }}>
      <div className="content-wrapper">
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div>
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th width="3%">S.No</th>
                        <th>Product Name</th>
                        <th>Packing</th>
                        <th width="9%">Price</th>
                        <th width="6%">Disc.%</th>
                        <th width="4%">Qty</th>
                        <th width="9%">Amount</th>
                        <th width="10%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderdetaildata?.orderItem?.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.packing}</td>

                            <td>{item.price}</td>
                            <td>{item.discount}</td>
                            <td>{item.quantity}</td>
                            <td>{item.total}</td>

                            <td className="actionBtn">
                              <a href="#">
                                <i
                                  className="fas fa-trash"
                                  onClick={() => handleDelete(item.id)}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div
                    className="orderTotalPrice"
                    style={{ width: "13%", margin: "auto" }}
                  >
                    <b>Total Value: {orderdetaildata?.order?.length&&orderdetaildata?.order[0]?.total} </b>
                  </div>

                  <Button
                    variant="primary"
                    style={{ marginLeft: "445px" }}
                    onClick={() => handleStatus()}
                    disabled={!cancelStyle}
                    className="mt-4"
                  >
                    {status}
                  </Button>

                  {cancelStyle && (
                    <Button
                      variant="danger"
                      style={{ display: "hidden" }}
                      onClick={() => handleCancel()}
                      className="mt-4"
                    >
                      cancel
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrderDetail;
