import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Api1 from "../services/Api1";
const AddCreditBill = () => {
  const [text, setText] = useState();
  const [partyname, setPartyname] = useState();
  const [date, setDate] = useState();
  const [on, setOn] = useState(false);
  const [showBill, setShowBill] = useState(true);
  const [bill, setBill] = useState();
  const [id, setId] = useState();
  const [value, setValue] = useState({});
  const [billData, setBillData] = useState();
  const [openTable, setOpenTable] = useState(true);

  const { isLoading, mutate } = useMutation(
    (data) => Api1.post("add-bill", data),
    {
      onSuccess: (res) => {
        console.log(res.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const { mutate: mutatebill } = useMutation(
    (data) => Api1.post("create-credit-bill", data),
    {
      onSuccess: (res) => {
        console.log(res);
        setBillData(res.data.bill_data);
        setShowBill(true);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log("billData", billData);

  const handlePost2 = () => {
    var body = Object.values(value);
    console.log(body);
    mutatebill(JSON.stringify(body));
  };

  const handlePost = () => {
    var body = { billno: bill, billdate: date };
    mutate(JSON.stringify(body));
    refetchbill();
    setShowBill(false);
  };

  const { data: billdata, refetch: refetchbill } = useQuery(
    ["billdata", text],
    () => Api1.get(`generate-credit-bill/${id}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );

  var ids = [];
  const handleValue = (e, index) => {
    ids = value;

    if (ids && ids[index]) {
      delete ids[index];
      setValue({ ...ids });
    } else {
      ids[index] = e.target.value;
      setValue({ ...ids });
    }
    console.log("value", value);
  };

  const { data: searchdata } = useQuery(
    ["searchdata", text],
    () => Api1.get(`search-credit-party/${text}`),
    {
      select: (res) => res.data,
    }
  );
  const handleSetData = (item) => {
    setOn(true);
    setPartyname(item.partyname);
    setId(item.id);
  };

  return (
    <div className="mainBody mt-5">
      <div className="innerPageSection">
        {on ? (
          <div className="formSection">
            <div className="container-fluid">
              <form>
                <div className="row">
                  <div className="col-md-4">
                    <label>Party name</label>
                    <input
                      type="text"
                      value={partyname}
                      name="party"
                      disabled
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Bill No.</label>
                    <input
                      type="text"
                      name="billno"
                      onChange={(e) => setBill(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Date</label>
                    <input
                      type="date"
                      name="billdate"
                      defaultValue={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-12">
                    <label style={{ color: "red" }}></label>
                    <div className="formBtn">
                      <input value="Add bill" onClick={() => handlePost()} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>
            <input type="text" onChange={(e) => setText(e.target.value)} />
            {searchdata &&
              searchdata.map((item) => {
                return (
                  <div onClick={() => handleSetData(item)}>{item.name}</div>
                );
              })}
          </div>
        )}

        {showBill ? (
          <div className="col-md-12">
            {billData &&
              billData.map((item, index) => {
                return (
                  <ul>
                    <li
                      style={{
                        fontSize: "20px",
                        textAlign: "center",
                        listStyle: "none",
                        fontWeight: "500",
                        background: "#174fa7",
                        color: "#fff",
                        padding: "5px",
                        width: "93%",
                        margin: "auto",
                        margin: "20px auto",
                      }}
                    >
                      Challan no.:{item["0"].invoiceno}{" "}
                      &nbsp;&nbsp;&nbsp;Purchase date:{item["0"].purchasedate}
                      &nbsp;&nbsp;&nbsp; Total amount: {item["0"].totalamount}
                    </li>
                    {item.products.map((item, index) => {
                      return (
                        <div className="row">
                          <div className="col-sm-8">
                            <ol>
                              <ul>
                                <li style={{ fontSize: "13px" }}>
                                  <b>Product name: </b> , &nbsp;&nbsp;
                                  <b>Packing: {item.packing} </b>
                                </li>
                              </ul>
                            </ol>
                          </div>
                          <div className="col-sm-4">
                            <ul>
                              <li style={{ listStyle: "none" }}>
                                <b>Quantity:{item.totalqty} </b>
                                ,&nbsp;&nbsp;&nbsp;{" "}
                                <b>Total: {item.totalamt} </b>
                                ,&nbsp;&nbsp;&nbsp;<b>GST: {item.gst} </b> %
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                );
              })}

            <div className="col-md-12">
              <h4 className="mt-4" style={{ textAlign: "center" }}>
                <b>
                  Grand total:-
                  {billData ? billData[0]["0"].totalamount : <>0</>}
                </b>{" "}
              </h4>
              <div className="formBtn">
                <input type="button" value="Save" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-md-4">
                <p>
                  <b>Party name:- </b>
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ textAlign: "center" }}>
                  <b>Bill no.:- </b>
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ textAlign: "right" }}>
                  <b>Bill date.:- </b>
                </p>
              </div>
            </div>
            <table className="tbl allAlignCenter">
              <tr>
                <th>Select</th>
                <th>Purchase date</th>
                <th>Challan no.</th>
                <th>Products</th>
                <th>Amount</th>
              </tr>

              {billdata &&
                billdata.credits.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          value={item.id}
                          onClick={(e) => handleValue(e, index)}
                        />
                        {/* {value} */}
                      </td>
                      <td>{item.purchasedate}</td>
                      <td>{item.invoiceno}</td>
                      <td>{item.procount}</td>
                      <td>{item.totalamount}</td>
                    </tr>
                  );
                })}
              <tr>
                <td colspan="4">Grand total:-</td>
                <td>{billdata ? billdata.grand : <>0</>}</td>
              </tr>
              <tr>
                <td colspan="5">
                  <button onClick={handlePost2}>Get bill</button>
                </td>
              </tr>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCreditBill;
