import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import Api1 from "../services/Api1";
import Api2 from "../services/Api2";

const InvoiceReport = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { data, refetch } = useQuery(
    ["invoicereport"],
    () => Api2.get(`/welcome/reportInvoice/${from}/${to}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );

  const { data: supplierdata } = useQuery(
    ["purchasedata"],
    () => Api1.get("purchase-supplier"),
    {
      select: (res) => res.data,
    }
  );

  console.log(data);

  var saleTotal = data?.sale.reduce((acc, item) => {
    return acc + parseInt(item.total);
  }, 0);

  var purTotal = data?.purchase.reduce((acc, item) => {
    return acc + parseInt(item.totalamount);
  }, 0);

  const handleReport = () => {
    refetch();
  };
  return (
    <div className="wrapper" style={{ marginTop: "80px" }}>
      <div className="content-wrapper">
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Search</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>From</label>
                        <input
                          type="date"
                          name="from"
                          className="form-control date"
                          onChange={(e) => setFrom(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>To</label>
                        <input
                          type="date"
                          name="to"
                          className="form-control date"
                          onChange={(e) => setTo(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <button
                          name="submit"
                          className="btn btn-success"
                          value="search"
                          onClick={() => handleReport()}
                          style={{ margin: "0" }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>from :- {from}</h4>
                    <h4>to :- {to}</h4>
                    <div className="text-right">
                      <h3
                        className="card-title text-danger"
                        style={{ float: "right", marginRight: "10px" }}
                      >
                        Sale :<span id="sold"> {saleTotal ?? 0} </span>
                      </h3>
                    </div>
                    <div className="text-right">
                      <h3
                        className="card-title text-warning"
                        style={{ float: "right", marginRight: "10px" }}
                      >
                        Purchase :<span id="purchase">{purTotal ?? 0}</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Sale Report</h3>
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>OrderId</th>
                          <th>Order Date</th>
                          <th>Customer</th>
                          <th>City</th>
                          <th>Portal</th>
                          <th>Amount</th>
                          <th>Payment</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.sale.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.order_id}</td>

                              <td>{item.orderdate}</td>

                              <td>
                                <a href="">
                                  {
                                    data?.customers?.find(
                                      (citem) => citem.id == item.customer_id
                                    )?.fullname
                                  }
                                </a>
                              </td>
                              <td>
                                {
                                  data?.customers?.find(
                                    (citem) => citem.id == item.customer_id
                                  )?.city
                                }
                              </td>
                              <td>{item.ordermode}</td>
                              <td>{item.total}</td>
                              <td>
                                <a href="">{item.paymentmode}</a>
                              </td>
                              <td>{item.order_status}</td>
                              <td></td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colspan="5">
                            <b>Total</b>
                          </td>
                          <td>
                            <b>{saleTotal ?? 0}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Purchase Report</h3>
                    <table
                      id="example1"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Invoice No.</th>
                          <th>Invoice Date</th>
                          <th>Party Name</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.purchase.map((item) => {
                          return (
                            <tr>
                              <td>
                                <a href="">{item.invoiceno}</a>
                              </td>

                              <td>{item.purchasedate}</td>

                              <td>
                                {
                                  supplierdata?.find(
                                    (sitem) => sitem.id == item.partyid
                                  )?.partyname
                                }
                              </td>
                              <td>{item.totalamount}</td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colspan="3">
                            <b>Total</b>
                          </td>
                          <td>
                            <b>{purTotal ?? 0}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default InvoiceReport;
