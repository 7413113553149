import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./common/Header";
import DashBoard from "./AdminPanel/DashBoard";
import PurchaseList from "./AdminPanel/PurchaseList";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PurchaseAdd from "./AdminPanel/PurchaseAdd";
import PurchaseReport from "./AdminPanel/PurchaseReport";
import CreditBill from "./AdminPanel/CreditBill";
import PartyAdd from "./AdminPanel/PartyAdd";
import AddCreditBill from "./AdminPanel/AddCreditBill";
import Login from "./Login";
import CounterSale from "./AdminPanel/CounterSale";
import CounterSaleAdd from "./AdminPanel/CounterSaleAdd";
import Report from "./AdminPanel/Report";
import CountersaleReturn from "./AdminPanel/CountersaleReturn";
import CounterSaleReport from "./AdminPanel/CounterSaleReport";
import CountersaleReturnAdd from "./AdminPanel/CounterSaleReturnAdd";
import PurchaseReturn from "./AdminPanel/PurchaseReturn";
import PurchaseInvoiceSearch from "./AdminPanel/PurchaseInvoiceSearch";
import PurchaseReturnAdd from "./AdminPanel/PurchaseReturnAdd";
import ProductWiseReport from "./AdminPanel/ProductWiseReport";
import ProductWiseDayReport from "./AdminPanel/ProductWiseDayReport";
import Orders from "./AdminPanel/Orders";
import OrderDetail from "./AdminPanel/OrderDetail";
import Layout from "./Layout";
import InvoiceReport from "./AdminPanel/InvoiceReport";
import OrdersRetailer from "./AdminPanel/OrdersRetailer";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<DashBoard />} path="/dashboard" />
          <Route element={<PurchaseList />} path="/purchaselist" />
          <Route element={<PurchaseAdd />} path="/purchaseadd" />
          <Route element={<PurchaseReport />} path="/purchasereport" />
          <Route element={<CreditBill />} path="/creditbill" />
          <Route element={<PartyAdd />} path="/partyadd" />
          <Route element={<AddCreditBill />} path="/creditadd" />
          <Route element={<Login />} path="/login" />
          <Route element={<CounterSale />} path="/countersale" />
          <Route element={<CounterSaleAdd />} path="/countersaleadd" />
          <Route element={<Report />} path="/report" />
          <Route element={<CountersaleReturn />} path="/countersalereturn" />
          <Route element={<CounterSaleReport />} path="/countersalereport" />
          <Route
            element={<CountersaleReturnAdd />}
            path="/countersalereturnadd"
          />
          <Route element={<PurchaseReturn />} path="/purchasereturn" />
          <Route element={<PurchaseReturnAdd />} path="/purchasereturnadd" />
          <Route element={<ProductWiseReport />} path="/productwisereport" />
          <Route
            element={<ProductWiseDayReport />}
            path="/productwisedayreport"
          />

          <Route
            element={<PurchaseInvoiceSearch />}
            path="/purchasereturninvoicesearch"
          />
          <Route element={<Orders />} path="/orders" />
          <Route element={<OrdersRetailer />} path="/ordersretailer" />

          <Route element={<OrderDetail />} path="/orderdetail" />
          <Route element={<InvoiceReport />} path="/invoicereport" />
          
        </Route>


        <Route element={<Login />} path="/" />
      </Routes>
    </Router>
  );
}

export default App;
