import React from "react";

const PurchaseReturnAdd = () => {
  return (
    <div>
      <div className="wrapper">
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Purchase Return</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="purchase">Purchase</a>
                    </li>
                    <li className="breadcrumb-item active">Purchase return</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Purchase Return</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label>Party Name :</label>
                      </div>
                      <div className="form-group col-md-4">
                        <label>Invoice No.:</label>
                      </div>
                      <div className="form-group col-md-4">
                        <label>Invoice Date :</label>
                      </div>
                      <div className="form-group col-md-3">
                        <label>Product Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="itemname"
                          id="itemname"
                          autocomplete="off"
                        />
                        <div className="row" id="products"></div>
                      </div>
                      <div className="form-group col-md-1">
                        <label>HSN Code</label>
                        <input
                          type="text"
                          className="form-control"
                          name="hsn"
                          id="hsn"
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Packing</label>
                        <input
                          type="text"
                          className="form-control"
                          name="pack"
                          id="pack"
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Expiry</label>
                        <input
                          type="text"
                          className="form-control"
                          name="exp"
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Batch No.</label>
                        <input
                          type="text"
                          className="form-control"
                          name="batch"
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>MRP</label>
                        <input
                          type="number"
                          className="form-control"
                          name="mrp"
                          id="mrp"
                          step="any"
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Rate</label>
                        <input
                          type="number"
                          className="form-control"
                          name="rate"
                          step="any"
                          id="whprice"
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          step="any"
                          name="qty"
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Free Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          name="fqty"
                          step="any"
                          value="0"
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>GST</label>
                        <input
                          type="number"
                          className="form-control"
                          name="gst"
                          id="gst"
                          required
                        />
                      </div>
                      <div className="form-group col-md-12 text-center">
                        <button name="submit" className="btn btn-success">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReturnAdd;
