import React from "react";

const DashBoard = () => {
  return (
    <div className="mainBody mt-5">
      <div className="dashboardSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="dashBrdBox">
                <i className="fas fa-users"></i>
                <h3>126</h3>
                <p>Total Customers</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="dashBrdBox">
                <i className="fas fa-users"></i>
                <h3>12</h3>
                <p>Total Orders</p>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
