import React from "react";
import { useQuery } from "@tanstack/react-query";
import Api2 from "../services/Api2";
import { Link } from "react-router-dom";

const PurchaseReturn = () => {
  const { data, refetch } = useQuery(
    ["purchasereturndata"],
    () => Api2.get(`/welcome/purchaseReturn`),
    {
      select: (res) => res.data,
    }
  );
  console.log(data);
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Purchase Return</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="purchase">Purchase</a>
                  </li>
                  <Link to="/purchasereturninvoicesearch">
                    {" "}
                    <li className="breadcrumb-item active">Purchase return</li>
                  </Link>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Purchase Return</h3>
                    <Link to="/purchasereturninvoicesearch">
                      <a
                        href="#"
                        className="btn btn-primary"
                        style={{ float: "right" }}
                      >
                        Add Purchase return
                      </a>
                    </Link>
                  </div>
                  <div className="card-body">
                    <table id="" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Sno.</th>
                          <th>Invoice no.</th>
                          <th>Party name</th>
                          <th>Product name</th>
                          <th>Packing</th>
                          <th>Batch no.</th>
                          <th>Expiry</th>
                          <th>Purchased Qty</th>
                          <th>Returned Qty</th>
                          <th>Avl Qty</th>
                          <th>Wholesale price</th>
                          <th>MRP</th>
                          <th>Total amount</th>
                          <th>Returned amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data?.returned_products?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {data?.purchase_details[index]?.map((pi) => {
                                    return <>{pi?.invoiceno}</>;
                                  })}
                                </td>
                                <td>{data?.suppliers[index]?.partyname}</td>
                                <td>
                                  {data?.product_names[index]?.map((pi) => {
                                    return <>{pi?.name}</>;
                                  })}
                                </td>
                                <td>{item.packing}</td>
                                <td>{item.batch}</td>
                                <td>{item.expiry}</td>
                                <td>{item.quantity}</td>
                                <td>{item.returned_qty}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <a
                                    href="purchase-return-invoice-search/<?= $purchase_details[$i][0]->invoiceno ?>"
                                    className="btn btn-primary"
                                  >
                                    Detail
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PurchaseReturn;
