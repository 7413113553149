import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Api1 from "../services/Api1";

const CreditBill = () => {
  const [btn, setBtn] = useState(false);
  const [id, setId] = useState();
  const [pid, setpid] = useState();
  const [quantity, setQuantity] = useState();
  const [freeQuantity, setFreeQuantity] = useState();
  const [gst, setGst] = useState();
  const [wholesale, setWholeSale] = useState();
  const [tquantity, setTempQuantity] = useState();
  const [tfreeQuantity, setTempFreeQuantity] = useState();
  const [tgst, setTempGst] = useState();
  const [twholesale, setTempWholeSale] = useState();

  const { data: billdata } = useQuery(
    ["creditdata", id],
    () => Api1.get(`bring-edit-bill-total/${id}`),
    {
      select: (res) => res.data,
    }
  );

  const { data: prodata, refetch: refetch1 } = useQuery(
    ["probill", id],
    () => Api1.get(`edit-credit-bill-products/${id}`),
    {
      select: (res) => res.data,
    }
  );

  const { data, refetch } = useQuery(
    ["prodata"],
    () => Api1.get("purchase-credit-bills"),
    {
      select: (res) => res.data,
    }
  );

  const { data: updata, refetch: refetchup } = useQuery(
    ["updata"],
    () =>
      Api1.get(
        `update-bill-product/${parseInt(pid)}/${
          quantity ? parseInt(quantity) : tquantity
        }/${freeQuantity ? parseInt(freeQuantity) : tfreeQuantity}/${
          gst ? parseInt(gst) : tgst
        }/${wholesale ? parseInt(wholesale) : twholesale}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );

  const { data: deldata, refetch: refetchdel } = useQuery(
    ["deldata"],
    () => Api1.get(`delete-bill/${id}`),
    {
      select: (res) => res.data,
    }
  );

  const handleEdit = (item) => {
    setBtn(true);
    setId(item);
  };

  const handleDelete = (item) => {
    setId(parseInt(item));
    setTimeout(() => {
      refetchdel();
      refetch();
    }, 250);
    // refetchdel()
  };

  const handleEdit2 = (item) => {
    console.log(item);
    setpid(parseInt(item.id));
    setTempQuantity(parseInt(item.quantity));
    setTempFreeQuantity(parseInt(item.free_quantity));
    setTempGst(parseInt(item.gst));
    setTempWholeSale(parseInt(item.wholesaleprice));
    setTimeout(() => {
      refetchup();
      refetch1();
    }, 2000);
  };

  return (
    <div className="mainBody mt-5">
      <div className="innerPageSection">
        <div className="container-fluid">
          <div className="row">
            <div className="headingSec">
              <ul>
                <li>
                  <a>Dashboard</a>
                </li>
                <li>
                  <a>Purchase List</a>
                </li>
                <li>Credit bill</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="addPanel">
          <div className="container-fluid">
            <div className="row">
              <div style={{ padding: 0 }} className="col-md-12" align="right">
                <Link to="/creditadd">
                  <button className="btn btn-primary mt-2">
                    <a>Add credit bill</a>
                  </button>
                </Link>
                <Link to="/creditbill">
                  <button className="btn btn-primary mt-2">
                    <a>View credit bills</a>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="formSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <label>Search Credit Party:</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="formSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <table
                  style={{ display: "none" }}
                  id="example1"
                  className="tbl allAlignCenter"
                >
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Party name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>

                {btn ? (
                  <table id="example1" className="tbl allAlignCenter">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Product Name</th>
                        <th>Packing</th>
                        <th>Quantity</th>
                        <th>Free quantity</th>
                        <th>Total quantity</th>
                        <th>GST</th>
                        <th>Wholesale price</th>
                        <th>MRP</th>
                        <th>Total amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prodata &&
                        prodata.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.product_name}</td>
                              <td>{item.packing}</td>
                              <td>
                                <input
                                  type="text"
                                  // value={item.quantity}
                                  size={5}
                                  placeholder={item.quantity}
                                  onChange={(e) => setQuantity(e.target.value)}
                                />
                                {/* {console.log("e",quantity)} */}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  //   value={item.free_quantity}
                                  placeholder={item.free_quantity}
                                  size="5"
                                  onChange={(e) =>
                                    setFreeQuantity(e.target.value)
                                  }
                                />
                              </td>
                              <td>{item.total_quantity}</td>
                              <td>
                                <input
                                  type="number"
                                  //   value={item.gst}
                                  style={{ width: "70px" }}
                                  placeholder={item.gst}
                                  onChange={(e) => setGst(e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  //   value={item.wholesaleprice}
                                  type="number"
                                  style={{ width: "70px" }}
                                  placeholder={item.wholesaleprice}
                                  onChange={(e) => setWholeSale(e.target.value)}
                                />
                              </td>
                              <td>{item.mrp}</td>
                              <td>{item.total_amount}</td>
                              <td>
                                <div className="formBtn">
                                  <input
                                    type="button"
                                    onClick={() => handleEdit2(item)}
                                    value="Update"
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colspan="11">
                          <div className="col-md-12">
                            <h4
                              style={{
                                textAlign: "left",
                                width: "50%",
                                float: "left",
                              }}
                            >
                              <b>Grand total:-</b>
                            </h4>
                            <label style={{ float: "right" }}>
                              Discount: <input type="number" name="discount" />
                            </label>
                            <div
                              className="formBtn"
                              style={{ padding: "55px 0px 10px" }}
                            >
                              <input type="button" value="Save" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <table id="example1" className="tbl allAlignCenter">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Party name</th>
                        <th>Bill number</th>
                        <th>Bill date</th>
                        <th>Paid amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.partyname}</td>
                              <td
                                style={{
                                  color: "royalblue",
                                  cursor: "pointer",
                                }}
                              >
                                {item.bill_no}
                              </td>
                              <td>{item.bill_date}</td>
                              <td>{item.total_amount}</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => handleEdit(item.id)}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}

                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Party name</label>
                      <input type="text" disabled className="form-control" />
                    </div>
                    <input type="hidden" name="billid" />
                    <div className="col-md-4">
                      <label>Bill No.</label>
                      <input
                        type="text"
                        name="billno"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Date</label>
                      <input
                        type="date"
                        name="billdate"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="formBtn">
                        <input type="submit" value="Update bill" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditBill;
