import { useQuery } from "@tanstack/react-query";
import { Button } from "bootstrap";
import React, { useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Api1 from "../services/Api1";

const PurchaseReport = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const {
    data,
    isLoading,
    refetch,
    error: inerror,
  } = useQuery(
    ["reportdata"],
    () => Api1.get(`get-purchase-report/${fromDate}/${toDate}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled:false,
      select: (res) => res.data,
    }
  );
  var gt = 0;
  var gst = 0;
  data &&
    data.data.forEach((item) => {
      // console.log(item.total_amount)
      gt = gt + item.total_amount;
      gst = gst + item.gst0 + item.gst5 + item.gst12 + item.gst18;
    });
  console.log(gst);

  return (
    <div>
      <div className="mainBody mt-5">
        <div className="innerPageSection">
          <div className="container-fluid">
            <div className="row">
              <div className="headingSec">
                <ul>
                  <li>
                    <a routerLink="/dashboard">Dashboard</a>
                  </li>
                  <li>Purchase Report</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="formSection">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <div className="row">
                      <div className="col-md-2">
                        <label>From Date</label>
                        <input
                          type="date"
                          name="from"
                          className="form-control"
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2">
                        <label>To Date</label>
                        <input
                          type="date"
                          name="to"
                          className="form-control"
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <div className="formBtn" style={{ marginTop: "26px" }}>
                          <input
                            onClick={() => refetch()}
                            value="Get Purchase Report"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button down"
                          table="table-to-xls"
                          filename="tablexls"
                          sheet="tablexls"
                          buttonText="Download as XLS"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="formSection">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <table id="table-to-xls" className="tbl allAlignCenter">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Bill/Invoice no.</th>
                        <th>Party name</th>
                        <th>GST no.</th>
                        <th>Bill/Purchase date</th>
                        <th>Net Purchase value</th>
                        <th>Bill value</th>
                        <th>CP 0%</th>
                        <th>LP</th>
                        <th>GST 0%</th>
                        <th>GST 5%</th>
                        <th>GST 12%</th>
                        <th>GST 18%</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.data.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.bill_invoice_no}</td>
                              <td>{item.party_name}</td>
                              <td>{item.gst_no}</td>
                              <td>{item.bill_purchase_date}</td>
                              <td></td>
                              <td>{item.total_amount.toFixed(2)}</td>
                              <td> {}</td>
                              <td> {}</td>
                              <td>{item.gst0.toFixed(2)}</td>
                              <td>{item.gst5.toFixed(2)}</td>
                              <td>{item.gst12.toFixed(2)}</td>
                              <td>{item.gst18.toFixed(2)}</td>
                            </tr>
                          );
                        })}

                      <tr
                        style={{ backgroundColor: "slategray", color: "white" }}
                      >
                        <td> </td>
                        <td colspan="6">
                          Grand Total(Without
                          GST)&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          {data ? data.total_without_gst.toFixed(2) : <>0</>}
                        </td>
                        <td> </td>
                        <td> </td>
                        <td colspan="4">
                          Total GST:&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          {data ? (
                            data.gst0total +
                            data.gst5total +
                            data.gst12total +
                            data.gst18total
                          ).toFixed(2) : (
                            <>0</>
                          )}
                        </td>
                      </tr>
                      <tr
                        style={{ backgroundColor: "slategray", color: "white" }}
                      >
                        <td> </td>
                        <td>
                          Gst 0%&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          {data ? data.gst0total.toFixed(2) : <>0</>}
                        </td>
                        Gst 5%&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        {data ? data.gst5total.toFixed(2) : <>0</>}
                        <td>
                          Gst 12%&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          {data ? data.gst12total.toFixed(2) : <>0</>}
                        </td>
                        <td>
                          Gst 18%&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          {data ? data.gst18total.toFixed(2) : <>0</>}
                        </td>
                        <td colspan="4">
                          Total GST:&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          {data ? (
                            data.gst0total +
                            data.gst5total +
                            data.gst12total +
                            data.gst18total
                          ).toFixed(2) : (
                            <>0</>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReport;
