import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api2 from "../services/Api2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const OrdersRetailer = () => {
  const navigate = useNavigate();
  const [fy, setFy] = useState();
  const [orders, setOrders] = useState([]);
  const [cname, setCname] = useState();
  const [city, setCity] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { data: allorderdata, refetch: refetchorders } = useQuery(
    ["financialyearretail"],
    () => Api2.get(`/welcome/ordersRetailer/${fy}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      //   enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: searchorderdata, refetch: refetchsearchorders } = useQuery(
    ["retailorders"],
    () => Api2.get(`/welcome/ordersearchRetail/${cname}/${city}/${from}/${to}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );
  useEffect(() => {
    if (searchorderdata) {
      var proorders = searchorderdata?.allOrders.map((item) => {
        return {
          ...item,
          cname:
            searchorderdata?.customers?.find(
              (citem) => citem.id == item.customer_id
            )?.fullname ?? "-",
          state:
            searchorderdata?.customers?.find(
              (citem) => citem.id == item.customer_id
            )?.state ?? "-",
        };
      });
    }
    setOrders(proorders);
  }, [searchorderdata]);

  useEffect(() => {
    if (allorderdata) {
      var proorders = allorderdata?.allOrders.map((item) => {
        return {
          ...item,
          cname: allorderdata?.customers?.find(
            (citem) => citem.id == item.customer_id
          )?.fullname ?? "-",
          state: allorderdata?.customers?.find(
            (citem) => citem.id == item.customer_id
          )?.state ?? "-" ,
        };
      });
    }
    setOrders(proorders);
  }, [allorderdata]);


console.log(orders)

  const handleOpen = (item) => {
    localStorage.setItem("item", JSON.stringify(item));
    navigate("/orderdetail");
  };

  const columns = [
    {
      name: "Sno",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "customer Name",
      selector: (row) => row?.cname,
      sortable: true,
    },
    {
      name: "OrderId",
      selector: (row) => row.order_no,
      sortable: true,
      right: true,
    },
    {
      name: "Date",
      selector: (row) => row.orderdate,
      sortable: true,
      right: true,
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
      right: true,
    },
    {
      name: "Payment Mode",
      selector: (row) => row.paymentmode,
      sortable: true,
      right: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      sortable: true,
      right: true,
    },
    {
      cell: (row) => <Button onClick={() => handleOpen(row)}>details</Button>,
    },
  ];

  return (
    <div className="wrapper" style={{ marginTop: "80px" }}>
      <div className="content-wrapper">
        <section className="content mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Financial Year</label>
                        <select
                          className="form-control"
                          name="year"
                          onChange={(e) => setFy(e.target.value)}
                        >
                          l-md-5
                          <option value="" disabled selected>
                            Select Financial Year
                          </option>
                          <option value="2021-2022"> 2021-2022</option>
                          <option value="2022-2023"> 2022-2023</option>
                        </select>
                      </div>
                      <div
                        className="form-group col-md-2 text-center"
                        style={{ paddingTop: "31px" }}
                      >
                        <button
                          name="submit"
                          className="btn btn-success"
                          value="search"
                          onClick={() => refetchorders()}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-3">
                        <label>Customer Name</label>
                        <input
                          type="text"
                          name="customer"
                          onChange={(e) => setCname(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>City Name</label>
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label>From Date</label>
                        <input
                          type="date"
                          name="from_date"
                          className="form-control"
                          onChange={(e) => setFrom(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label>To Date</label>
                        <input
                          type="date"
                          name="to_date"
                          className="form-control"
                          onChange={(e) => setTo(e.target.value)}
                        />
                      </div>
                      <div
                        className="form-group col-md-2 text-center"
                        style={{ paddingTop: "31px" }}
                      >
                        <button
                          name="submit"
                          className="btn btn-success"
                          value="search"
                          onClick={() => refetchsearchorders()}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <DataTableExtensions columns={columns} data={orders}>
                <DataTable
                  title="Orders"
                  defaultSortFieldID={1}
                  pagination
                  selectableRows
                />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrdersRetailer;
