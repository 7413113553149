import React from "react";
import { useState } from "react";
import Api1 from "../services/Api1";
import Api2 from "../services/Api2";
import { useQuery } from "@tanstack/react-query";
const ProductWiseReport = () => {
  const [txt, setTxt] = useState();
  const [proid, setProid] = useState();

  const { data: proreportdata, refetch: refetchproreportdata } = useQuery(
    ["proreport"],
    () => Api2.get(`/welcome/report_by_product/${proid}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,

      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: searchdata } = useQuery(
    ["searchdata", txt],
    () => Api2.get(`/welcome/getProducts/${txt}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled:false,

      select: (res) => res.data,
    }
  );

  const { data: productsdata } = useQuery(
    ["products", txt],
    () => Api2.get(`/welcome/getAllProducts`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled:false,

      select: (res) => res.data,
    }
  );

  const handleText = (item) => {
    setTxt(item.name);
    setProid(item.id);
    setTimeout(() => {
      refetchproreportdata();
    }, 1000);
  };

  console.log(proreportdata);

  var report = proreportdata?.purchaseItems?.map((item) => {
    return {
      date: item.created_at,
      purchased: item.quantity,
      sale: proreportdata.saleItems?.reduce((acc, sitem) => {
        if (sitem.batch == item.batch && sitem.product_id == item.productid) {
          return acc + parseInt(sitem.quantity);
        } else {
          return acc;
        }
      }, 0),
      proname: productsdata?.find((pitem) => pitem.id == item.productid).name,
      partyname: proreportdata?.purchases?.find(
        (puritem) => puritem.id == item.purchaseid
      )?.partyid,
    };
  });

  console.log(report);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Productwise Report</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Report</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Search</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          name="itemname"
                          id="itemname"
                          autocomplete="off"
                          value={txt}
                          onChange={(e) => setTxt(e.target.value)}
                        />

                        <div className="row" id="products">
                          {searchdata &&
                            searchdata.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => handleText(item)}
                                >
                                  {item.name}
                                  {"   "}
                                  {item.packing}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="form-group col-md-2 text-center">
                        <button
                          name="submit"
                          className="btn btn-success"
                          value="search"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="text-right">
                      <h3
                        className="card-title text-danger"
                        style={{ float: "right", marginRight: "10px" }}
                      >
                        Sale :<span id="sold"></span>
                      </h3>
                    </div>
                    <div className="text-right">
                      <h3
                        className="card-title text-warning"
                        style={{ float: "right", marginRight: "10px" }}
                      >
                        Purchase :<span id="purchase"></span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card-body">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Party Name</th>
                        <th>Product Name</th>
                        <th>Purchased</th>
                        <th>Sale</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {report?.map((item, index) => {
                        return (
                          <tr style={{ color: "blue" }} key={index}>
                            <td>{item.date}</td>
                            <td></td>
                            <td>{item.proname}</td>
                            <td>{item.purchased}</td>

                            <td>{item.sale}</td>
                            <td>
                              {parseInt(item.purchased) - parseInt(item.sale)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProductWiseReport;
