import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Navigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { useRef } from "react";
import Api1 from "../services/Api1";
import Api2 from "../services/Api2";
import { Button } from "react-bootstrap";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const PurchaseAdd = () => {
  var pfocus = useRef();
  const change = useRef("");
  const [partyid, setPartyId] = useState();
  const [invoice, setInvoice] = useState();
  const [mode, setMode] = useState("cash");
  const [save, setSave] = useState(false);
  const [text, setText] = useState();
  const [searchData, setSearchData] = useState([]);
  const [packing, setPacking] = useState("");
  const [mrp, setMrp] = useState();
  const [rate, setRate] = useState();
  const [gst, setGst] = useState();
  const [id, setId] = useState();
  const [expiry, setExpiry] = useState();
  const [batch, setBatch] = useState();
  const [qty, setQty] = useState();
  const [free, setFreeQty] = useState();
  const [purchaseid, setPurchaseId] = useState();
  const [discount, setDiscount] = useState(0);
  const [discountedAmt, setDiscountedAmt] = useState(0);
  const [backspace, setBackspace] = useState(false);
  const [hsn, setHsn] = useState();
  const [billdate, setBillDate] = useState(moment().format("YYYY-MM-DD"));
  const [update, setUpdate] = useState(false);
  const [products, setProducts] = useState();
  const [show, setShow] = useState(false);
  const [cursor, setCursor] = useState(0);

  const {
    data: prodata,

    refetch,
  } = useQuery(
    ["prodata"],
    () =>
      Api1.get(
        `add-purchase-product/${purchaseid}/${id}/${hsn}/${packing}/${expiry}/${batch}/${mrp}/${rate}/${qty}/${free}/${gst}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const {
    data: proupdata,

    refetch: refetchproup,
  } = useQuery(
    ["proupdata"],
    () =>
      Api2.get(
        `/welcome/updatePurchaseProduct/${purchaseid}/${id}/${hsn}/${packing}/${expiry}/${batch}/${mrp}/${rate}/${qty}/${free}/${gst}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: partydata, refetch: partyrefetch } = useQuery(
    ["tabledata"],
    () =>
      Api1.get(`add-purchase-order/${partyid}/${invoice}/${billdate}/${mode}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );

  const { data: upmaster, refetch: refetchmaster } = useQuery(
    ["upmaster"],
    () => Api2.get(`/update-master/${packing}/${hsn}/${mrp}/${gst}/${id}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );

  const { data: productadd, refetch: refetchproductadd } = useQuery(
    ["productadd", save],
    () => Api1.get(`add-purchase-discount/${purchaseid}/${discount}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => {
        window.location.reload();
      },
    }
  );

  const { data: searchdata } = useQuery(
    ["searchdata", text],
    () => Api2.get(`/welcome/getProducts/${text}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled:false,

      select: (res) => res.data,
    }
  );

  // setSearchData(searchdata)

  const { data } = useQuery(
    ["purchasedata"],
    () => Api1.get("purchase-supplier"),
    {
      select: (res) => res.data,
    }
  );

  useEffect(() => {
    if (prodata) {
      setProducts(prodata);
    }
  }, [prodata]);

  useEffect(() => {
    if (proupdata) {
      setProducts(proupdata);
    }
  }, [proupdata]);

  console.log(products);

  const handlePartyAdd = () => {
    setShow(true);
    partyrefetch();
  };

  const handleChange = (text) => {
    let textTemp = text;
    if (textTemp[0] !== "1" && textTemp[0] !== "0") {
      textTemp = "";
    }
    if (text.length === 2) {
      if (
        parseInt(textTemp.substring(0, 2)) > 12 ||
        parseInt(textTemp.substring(0, 2)) == 0
      ) {
        textTemp = textTemp[0];
      } else if (text.length === 2 && !backspace) {
        textTemp += "/";
        setBackspace(true);
      } else {
        textTemp = textTemp[0];
        setBackspace(false);
      }
    }
    setExpiry(textTemp);
  };

  const handleSetData = (item) => {
    setHsn(item.hsncode);
    setPacking(item.packing);
    setMrp(item.mrp);
    setRate(item.wholesaleprice);
    setGst(item.gst);
    setId(item.id);
    setText(item.name);
    setFreeQty(0);
  };

  const handleAddProduct = () => {
    var tempdate = expiry.replace("/", "-");
    var tempPacking = packing.replace("'", "-");
    setPacking(tempPacking);
    console.log(tempdate);
    setExpiry(tempdate);
    setPurchaseId(partydata.id);

    setTimeout(() => {
      refetchmaster();
      refetch();
    }, 1000);
    setTimeout(() => {
      setMrp("");
      setRate("");
      setHsn("");
      setBatch("");
      setQty("");
      setFreeQty("");
      setText("");
      setPacking("");
      setExpiry("");
      setQty("");
      setGst("");
      pfocus.current.focus();
    }, 5000);
  };

  //
  const handleUpdateProduct = () => {
    console.log("pppppppp", partydata);
    var tempdate = expiry.replace("/", "-");
    setExpiry(tempdate);
    var tempPacking = packing.replace("'", "-");
    setPacking(tempPacking);
    setPurchaseId(partydata.id);

    setTimeout(() => {
      refetchmaster();
      refetchproup();
      // refetch();
    }, 1000);
    setTimeout(() => {
      setMrp("");
      setRate("");
      setHsn("");
      setBatch("");
      setQty("");
      setFreeQty("");
      setText("");
      setPacking("");
      setExpiry("");
      setQty("");
      setGst("");
    }, 5000);
    setUpdate(!update);
  };

  const handleMasterChange = (e) => {
    if (change.current == "") {
      var ch = prompt("do you want to change");
      change.current = ch;
      // setPacking(e.target.value);
    } else {
      setPacking(e.target.value);
    }
  };

  // const handleMasterChangeMrp = (e) => {
  //   if (change.current == "") {
  //     var ch = prompt("do you want to change");
  //     change.current = ch;
  //     // setPacking(e.target.value);
  //   } else {
  //     setMrp(e.target.value);
  //   }
  // };
  const handleEdit = (item) => {
    setUpdate(true);
    setId(item.productid);
    setMrp(item.mrp);
    setRate(item.wholesaleprice);
    setHsn(item.hsncode);
    setBatch(item.batch);
    setQty(item.quantity);
    setFreeQty(item.freeqty);
    setText(item.pro_name);
    setPacking(item.packing);
    setExpiry(item.expiry);
    setGst(item.gst);
  };
  console.log(id);
  const handleCal = (e) => {
    setDiscount(e.target.value);
    var disc = prodata.subTotal * (parseInt(e.target.value) / 100);
    console.log(disc);
    e.target.value ? setDiscountedAmt(disc) : setDiscountedAmt(0);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode == 40) {
      setCursor((prev) => {
        return prev < searchdata?.length ? prev + 1 : 0;
      });
    }
    if (e.keyCode == 38) {
      setCursor((prev) => {
        return prev > 0 ? prev - 1 : searchdata?.length - 1;
      });
    }
    if(e.key=='Enter'){
      handleSetData(searchdata[cursor])
    }
  };

  const handlePrint = () => {
    var content = document.getElementById("divcontents");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  var sortdata =
    data &&
    data.sort((a, b) =>
      a.partyname > b.partyname ? 1 : b.partyname > a.partyname ? -1 : 0
    );
  

  return (
    <div className="mainBody mt-5" tabindex="0">
      <div className="innerPageSection">
        <div className="container-fluid">
          <div className="row">
            <div className="headingSec">
              <ul>
                <li>
                  <a>Dashboard</a>
                </li>
                <li>Add Purchase order</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="formSection">
          <div className="container-fluid">
            <form>
              <div className="row">
                <div className="col-md-3 form-group">
                  <label>Party Name</label>
                  <select
                    name="partyid"
                    className="form-control"
                    onChange={(e) => setPartyId(e.target.value)}
                  >
                    <option value="#">--select--</option>
                    {sortdata &&
                      sortdata.map((item) => {
                        return (
                          <option value={item.id}>{item.partyname}</option>
                        );
                      })}
                  </select>
                </div>

                <div className="col-md-2 form-group">
                  <label>Purchase Date</label>
                  <input
                    type="date"
                    name="purchasedate"
                    className="form-control"
                    value={billdate}
                    onChange={(e) => setBillDate(e.target.value)}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <label>Invoice No.</label>
                  <input
                    type="text"
                    name="invoiceno"
                    style={{ textTransform: "uppercase" }}
                    className="form-control"
                    onChange={(e) => setInvoice(e.target.value)}
                  />
                  <h5 style={{ color: "red" }}>
                    {" "}
                    {partydata && partydata?.Exist}
                  </h5>
                </div>
                <div className="col-md-2 form-group">
                  <label>Payment Mode</label>
                  <select
                    value={mode}
                    name="paymentmode"
                    onChange={(e) => setMode(e.target.value)}
                    className="form-control"
                  >
                    <option value="cash">Cash</option>
                    <option value="credit">Credit</option>
                  </select>
                </div>

                <div className="col-md-2">
                  <label></label>
                  <div className="formBtn formBtn2">
                    <Button
                      variant="primary"
                      onClick={() => handlePartyAdd()}
                      disabled={show}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
            </form>
          </div>
          <label style={{ color: "red" }}></label>
        </div>

        <div className="formSection">
          <div className="container-fluid">
            <form>
              <div className="row">
                <div className="col-md-2 form-group searchBoxGroup">
                  <label>Product Name</label>

                  <input
                    type="text"
                    className="form-control"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    ref={pfocus}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <div className="row" id="products">
                    {searchdata &&
                      searchdata.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={cursor == index ? "arrowDown" : ""}
                            onClick={() => handleSetData(item)}
                          >
                            {item.name}
                            {"   "}
                            {item.packing}
                            
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-md-1 form-group">
                  <label>HSN Code</label>
                  <input
                    type="text"
                    name="hsn"
                    value={hsn}
                    className="form-control"
                    onChange={(e) => setHsn(e.target.value)}
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>Packing</label>
                  <input
                    type="text"
                    value={packing}
                    name="packing"
                    onChange={(e) => setPacking(e.target.value)}
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>Expiry</label>
                  <input
                    type="text"
                    name="expiry"
                    placeholder="MM/YY"
                    className="form-control"
                    value={expiry}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>Batch No.</label>
                  <input
                    type="text"
                    name="batch"
                    className="form-control"
                    value={batch}
                    onChange={(e) => setBatch(e.target.value)}
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>MRP</label>
                  <input
                    type="text"
                    name="mrp"
                    value={mrp}
                    className="form-control"
                    onChange={(e) => setMrp(e.target.value)}
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>Rate</label>
                  <input
                    type="text"
                    name="rate"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>Qty</label>
                  <input
                    type="number"
                    name="qty"
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>Free Qty</label>
                  <input
                    type="number"
                    name="free"
                    className="form-control"
                    value={free}
                    onChange={(e) => setFreeQty(e.target.value)}
                  />
                </div>
                <div className="col-md-1 form-group">
                  <label>GST</label>
                  <input
                    type="text"
                    name="gst"
                    maxlength="2"
                    className="form-control"
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                  />
                </div>

                <div className="col-md-1">
                  {update ? (
                    <Button
                      variant="dark"
                      onClick={() => handleUpdateProduct()}
                    >
                      Update
                    </Button>
                  ) : (
                    <div className="formBtn">
                      <img
                        src="icons/plusicn.png"
                        className="ww"
                        onClick={() => handleAddProduct()}
                      />
                    </div>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </form>
          </div>
        </div>

        <div className="formSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12" id="divcontents">
                <table id="example1" className="tbl allAlignCenter">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Product Name</th>
                      <th>HSN Code</th>
                      <th>Packing</th>
                      <th>Batch</th>
                      <th>Expiry</th>
                      <th>Qty</th>
                      <th>Free Qty</th>
                      <th>Total Qty</th>
                      <th>Rate</th>
                      <th>Mrp</th>
                      <th>GST</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.products?.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.pro_name}</td>
                            <td>{item.hsncode}</td>
                            <td>{item.packing}</td>
                            <td>{item.batch}</td>
                            <td>{item.expiry}</td>
                            <td>{item.quantity}</td>
                            <td>{item.freeqty}</td>
                            <td>{item.totalqty}</td>
                            <td>{item.wholesaleprice}</td>
                            <td>{item.mrp}</td>
                            <td>{item.gst}%</td>
                            <td>{item.totalamt}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleEdit(item)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </button>
                              <button type="button">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style={{ textAlign: "right" }} colspan="12">
                        <b>SubTotal</b>
                      </td>
                      <td>
                        <b>Rs.{prodata ? prodata.subTotal : 0}</b>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "right" }} colspan="12">
                        <b>Discount(%)</b>
                      </td>
                      <td>
                        <input
                          style={{ width: "60px" }}
                          type="text"
                          value={discount}
                          onChange={(e) => handleCal(e)}
                        />
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td style={{ textAlign: "right" }} colspan="12">
                        <b>GST</b>
                      </td>
                      <td>
                        <b>Rs.{products?.gst ? products.gst : 0}</b>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "right" }} colspan="12">
                        <b>Total</b>
                      </td>
                      <td>
                        <b>
                          Rs.
                          {products?.subTotal
                            ? Math.round(
                                products.subTotal + products.gst - discountedAmt
                              )
                            : 0}
                        </b>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="col-md-12 form-group">
                <div align="right" className="btn2">
                  <button onClick={() => refetchproductadd()}>
                    Save & Exit
                  </button>
                  <button onClick={() => handlePrint()}>Save & Print</button>
                </div>
              </div>
              <iframe
                id="ifmcontentstoprint"
                style={{ display: "none" }}
              ></iframe>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseAdd;
