import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Api2 from "../services/Api2";

const Report = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [category, setCategory] = useState();

  const { data: reportdata, refetch: reportrefetch } = useQuery(
    ["reportdata", to],
    () =>
    Api2.get(
        `/welcome/getReport/${from}/${to}/${category}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );

  // reportdata.sale_order_products.map((item,index)=>{
  //     console.log(item[index])
  // })

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Report</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a>Home</a>
                </li>
                <li className="breadcrumb-item active">Report</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="mainBody">
        <div className="innerPageSection">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Search</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-3">
                          <label>Category</label>
                          <select
                            className="form-control"
                            style={{ width: "100%" }}
                            name="type"
                            onChange={(e) => setCategory(e.target.value)}
                            required
                          >
                            <option value="">Select</option>
                            <option value="Sale">Sale</option>
                            <option value="Purchase">Purchase</option>
                          </select>
                        </div>
                        <div className="form-group col-md-3">
                          <label>Search Type</label>
                          <select
                            className="form-control"
                            style={{ width: "100%" }}
                            name="stype"
                            id="stype"
                            required
                          >
                            <option value="date">Date</option>
                            <option value="all">All</option>
                          </select>
                        </div>
                        <div className="form-group col-md-2">
                          <label>From</label>
                          <input
                            type="date"
                            name="from"
                            onChange={(e) => setFrom(e.target.value)}
                            className="form-control date"
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label>To</label>
                          <input
                            type="date"
                            name="to"
                            onChange={(e) => setTo(e.target.value)}
                            className="form-control date"
                          />
                        </div>

                        <div
                          className="form-group col-md-2 text-center"
                          style={{ paddingTop: "30px" }}
                        >
                          <button
                            name="submit"
                            className="btn btn-success"
                            value="search"
                            onClick={() => reportrefetch()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12"
                  style={{ width: "80%", position: "relative", left: 350 }}
                >
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Report of </h3>
                      <div className="text-right">
                        <h3
                          className="card-title text-success"
                          style={{ float: "right" }}
                        >
                          <span id="qty"></span>
                        </h3>
                      </div>
                      <div className="text-right">
                        <h3
                          className="card-title text-danger"
                          style={{ float: "right", marginRight: "10px" }}
                        >
                          <span id="count"></span>
                        </h3>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <br />
                        <br />
                      </div>
                      <table
                        id=""
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>MRP</th>

                            <th>Packing</th>

                            <th>Expiry</th>
                            <th>Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportdata &&
                            reportdata?.sale_order_products.map(
                              (item, index) => {
                                return (
                                  <>
                                    {item.length ? (
                                      item &&
                                      item?.length &&
                                      item?.map((citem) => {
                                        return (
                                          <tr>
                                            <td>
                                              <a href="#"></a>
                                            </td>
                                            <td>{citem?.price}</td>

                                            <td></td>

                                            <td>{citem?.expiry}</td>
                                            <td>{citem?.quantity}</td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <> </>
                                    )}
                                  </>
                                );
                              }
                            )}

                          <tr>
                            <td colspan="4">
                              <b>Total</b>
                            </td>
                            <td>
                              <b></b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
