import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Api1 from "../services/Api1";

const PartyAdd = () => {
  const [party, setParty] = useState();
  const [gst, setGst] = useState();
  const [dl, setDL] = useState();
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState();
  const [owner, setOwner] = useState();
  const [up, setUp] = useState(false);
  const [id, setId] = useState();

  const {
    data,

    refetch,
  } = useQuery(
    ["prodata"],
    () =>
    Api1.get(
        `add-purchase-supplier/${party}/${gst}/${dl}/${address}/${contact}/${owner}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );

  const {
    data: updata,

    refetch: refetchup,
  } = useQuery(
    ["updata"],
    () =>
    Api1.get(
        `update-purchase-supplier/${id}/${party}/${gst}/${dl}/${address}/${contact}/${owner}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,

      select: (res) => {
        refetchsup();
      },
    }
  );

  const {
    data: supdata,
    isLoading,
    refetch: refetchsup,
    error: inerror,
  } = useQuery(
    ["supdata"],
    () => Api1.get("purchase-supplier"),
    {
      select: (res) => res.data,
    }
  );

  const handleUp = (item) => {
    setId(item.id);
    setParty(item.partyname);
    setGst(item.gstno);
    setDL(item.dlno);
    setAddress(item.address);
    setOwner(item.ownername);
    setContact(item.contactno);
    setUp(true);
  };

  return (
    <div>
      <div className="mainBody mt-5">
        <div className="innerPageSection">
          <div className="container-fluid">
            <div className="row">
              <div className="headingSec">
                <ul>
                  <li>
                    <a>Dashboard</a>
                  </li>
                  <li>Add Party</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="formSection">
            <div className="container-fluid">
              {up ? (
                <form>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label>Party Name</label>
                      <input
                        type="text"
                        value={party}
                        onChange={(e) => setParty(e.target.value)}
                        name="party"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>GST No.</label>
                      <input
                        type="text"
                        value={gst}
                        onChange={(e) => setGst(e.target.value)}
                        name="gst"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>DL No.</label>
                      <input
                        type="text"
                        value={dl}
                        onChange={(e) => setDL(e.target.value)}
                        name="dl"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        value={address}
                        name="address"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Owner Name</label>
                      <input
                        type="text"
                        value={owner}
                        onChange={(e) => setOwner(e.target.value)}
                        name="owner"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Contact No.</label>
                      <input
                        type="text"
                        value={contact}
                        name="contact"
                        className="form-control"
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="formBtn">
                        <input onClick={() => refetchup()} value="Update" />
                        <input type="reset" value="Reset" />
                      </div>
                    </div>

                    <div className="clearfix"></div>
                  </div>
                </form>
              ) : (
                <form>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label>Party Name</label>
                      <input
                        type="text"
                        onChange={(e) => setParty(e.target.value)}
                        name="party"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>GST No.</label>
                      <input
                        type="text"
                        onChange={(e) => setGst(e.target.value)}
                        name="gst"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>DL No.</label>
                      <input
                        type="text"
                        onChange={(e) => setDL(e.target.value)}
                        name="dl"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Owner Name</label>
                      <input
                        type="text"
                        onChange={(e) => setOwner(e.target.value)}
                        name="owner"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Contact No.</label>
                      <input
                        type="text"
                        name="contact"
                        className="form-control"
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="formBtn">
                        <input onClick={() => refetch()} value="Submit" />
                        <input type="reset" value="Reset" />
                      </div>
                    </div>

                    <div className="clearfix"></div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>

        <div className="formSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <table id="example1" className="tbl allAlignCenter">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>GST No.</th>
                      <th>DL No.</th>
                      <th>Address</th>
                      <th>Contact</th>
                      <th>Owner</th>
                      <th>Reg. Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {supdata &&
                      supdata.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.partyname}</td>
                            <td>{item.gstno}</td>
                            <td>{item.dlno}</td>
                            <td>{item.address}</td>
                            <td>{item.contactno}</td>
                            <td>{item.ownername}</td>
                            <td>{item.dateofreg}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleUp(item)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </button>
                              <button type="button">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyAdd;
