import React from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import Api1 from "../services/Api1";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import Api2 from "../services/Api2";
import { useEffect } from "react";

const ModalShow = (props) => {
  console.log("props.data", props.data);

  var gst0 = 0;
  var gst5 = 0;
  var gst12 = 0;
  var gst18 = 0;
  var subtotal = 0;

  props.data?.purchaseItems?.forEach((item) => {
    if (item.gst == "0") {
      gst0 = gst0 + (parseInt(item.gst) / 100) * parseInt(item.totalamt);
    }
    if (item.gst == "5") {
      gst5 = gst5 + (parseInt(item.gst) / 100) * parseInt(item.totalamt);
    }
    if (item.gst == "12") {
      gst12 = gst12 + (parseInt(item.gst) / 100) * parseInt(item.totalamt);
    }
    if (item.gst == "18") {
      gst18 = gst18 + (parseInt(item.gst) / 100) * parseInt(item.totalamt);
    }
    subtotal += parseInt(item.totalamt);
  });

  const handlePrint = () => {
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    var content = document.getElementById("divcontent");
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();

    pri.focus();
    pri.print();
  };

  return (
    <Modal
      show={props.show}
      dialogClassName="modal-100w"
      onHide={() => props.handleClose()}
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title>Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table
          id="divcontent"
          width="100%"
          border="0"
          style={{
            fontSize: "11px",
            fontFamily: "Roboto sans-serif",
            color: "#636363",
            lineHeight: "20px",
          }}
          cellspacing="0"
          cellpadding="0"
        >
          <tr>
            <td align="center">
              <table width="850px" border="1" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <td colspan="10" style={{ padding: "15px 20px" }}>
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tr>
                          <td width="60%">
                            <h4 style={{ margin: "8px 0 2px" }}>Sold By:</h4>
                            <p style={{ margin: 0 }}></p>
                            <p style={{ margin: 0 }}>
                              <b>Drug Lic No.:</b>
                            </p>
                            <p style={{ margin: 0 }}>
                              <b>GSTIN:</b>
                            </p>
                          </td>
                          <td width="40%">
                            <h4 style={{ margin: "8px 0 2px" }}>Sold To:</h4>
                            <p style={{ margin: 0 }}>People's Pharmacy</p>
                            <p style={{ margin: 0 }}>
                              <b>Drug Lic No.:</b> 20/782/57/2018,21/783/57/2017
                            </p>
                            <p style={{ margin: 0 }}>
                              <b>GSTIN:</b> 23CZNPS7620G1Z3
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ background: "#ddd", color: "#000" }}>
                    <th width="30px" style={{ padding: "5px" }}>
                      S.No.
                    </th>
                    <th style={{ padding: "5px" }}>Item Name & Packing</th>
                    <th width="8%" style={{ padding: "5px" }}>
                      Batch No.
                    </th>
                    <th width="6%" style={{ padding: "5px" }}>
                      Expiry
                    </th>
                    <th width="6%" style={{ padding: "5px" }}>
                      HSN Code
                    </th>
                    <th width="5%" style={{ padding: "5px" }}>
                      Qty
                    </th>
                    <th width="5%" style={{ padding: "5px" }}>
                      Free
                    </th>
                    <th width="6%" style={{ padding: "5px" }}>
                      MRP
                    </th>
                    <th width="6%" style={{ padding: "5px" }}>
                      Rate
                    </th>
                    <th width="10%" style={{ padding: "5px" }}>
                      Amount
                    </th>
                  </tr>
                  {props.data?.purchaseItems?.map((item, index) => {
                    return (
                      <tr align="center">
                        <td style={{ padding: "5px" }}>{index + 1}</td>
                        <td align="left" style={{ padding: "5px" }}>
                          {item.packing}{" "}
                        </td>
                        <td style={{ padding: "5px" }}>{item.batch}</td>
                        <td style={{ padding: "5px" }}>{item.expiry}</td>
                        <td style={{ padding: "5px" }}>{item.hsncode}</td>
                        <td style={{ padding: "5px" }}>{item.quantity}</td>
                        <td style={{ padding: "5px" }}>{item.freeqty}</td>
                        <td style={{ padding: "5px" }}>{item.mrp}</td>
                        <td style={{ padding: "5px" }}>
                          {item.wholesaleprice}
                        </td>
                        <td style={{ padding: "5px" }}>{item.totalamt}</td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colspan="8">
                      <table width="100%">
                        <thead>
                          <tr>
                            <th>Class</th>
                            <th>Total</th>
                            <th>SGST</th>
                            <th>CGST</th>
                            <th>Total GST</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>GST 5%</td>
                            <td>{gst5}</td>
                            <td>{0}</td>
                            <td>{0}</td>
                            <td>{gst5}</td>
                          </tr>
                          <tr>
                            <td>GST 12%</td>
                            <td>{gst12}</td>
                            <td>{0}</td>
                            <td>{0}</td>
                            <td>{gst12}</td>
                          </tr>
                          <tr>
                            <td>GST 18%</td>
                            <td>{gst18}</td>
                            <td>{0}</td>
                            <td>{0}</td>
                            <td>{gst18}</td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td>{gst5 + gst12 + gst18}</td>
                            <td>{0}</td>
                            <td>{0}</td>
                            <td>{gst5 + gst12 + gst18}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td colspan="2">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <th>Sub Total</th>
                            <td>{subtotal}</td>
                          </tr>
                          <tr>
                            <th>SGST Payble</th>
                            <td>{0}</td>
                          </tr>
                          <tr>
                            <th>GST Payble</th>
                            <td>{0}</td>
                          </tr>
                          <tr>
                            <th>Discount</th>
                            <td>%</td>
                          </tr>
                          <tr>
                            <th>Grand Total</th>
                            <td>{subtotal + gst5 + gst12 + gst18}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
        <iframe id="ifmcontentstoprint" style={{ display: "none" }}></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handlePrint}>
          print
        </Button>

        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={props.handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PurchaseList = () => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState();

  const { data, isLoading, refetch, error } = useQuery(
    ["tabledata"],
    () => Api1.get("all-purchase-invoices"),
    {
      select: (res) => res.data,
    }
  );





  const { data: invoicedata, refetch: refetchinvoice } = useQuery(
    ["purchaseinvoice"],
    () => Api2.get(`welcome/purchaseInvoice/${id}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );
  useEffect(() => {
    refetchinvoice();
  }, [id]);

const funevent=(e)=>{
  alert('hi')
  console.log(e)
}





  const handleSaveAndInvoice = (id) => {
    console.log(id);
    setId(id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  console.log(data);

  return (
    <div>
      <div className="mainBody mt-5">
        <div className="innerPageSection">
          <div className="container-fluid">
            <div className="row">
              <div className="headingSec">
                <ul>
                  <li>
                    <a>Dashboard</a>
                  </li>
                  <li>Purchase List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="addPanel">
            <div className="container-fluid">
              <div className="row">
                <div
                  style={{ padding: 0 }}
                  className="col-md-12 mt-2"
                  align="right"
                >
                  <Link to="/purchasereturn">
                    <a href="#" className="btn btn-primary">
                      Add Purchase Return
                    </a>
                  </Link>
                  <Link to="/purchaseadd">
                    <a href="#" className="btn btn-primary">
                      Add Purchase
                    </a>
                  </Link>
                  <Link to="/partyadd">
                    <a href="#" className="btn btn-primary">
                      Add Party
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="formSection">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <table
                    id="example1"
                    datatable
                    className="tbl allAlignCenter row-border hover"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Invoice Date</th>
                        <th>Invoice No.</th>
                        <th>Party Name</th>
                        <th>Payment Mode</th>
                        <th>Total Amount</th>
                        <th>Entry Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.entrydate}</td>
                              <td>{item.invoiceno}</td>
                              <td>{item.party_name}</td>
                              <td>{item.paymentmode}</td>
                              <td>{item.totalamount}</td>
                              <td>{item.entrydate}</td>
                              <td>
                                <button
                                  onClick={() => handleSaveAndInvoice(item.id)}
                                >
                                  <i class="fas fa-print"></i>
                                </button>
                                <button type="button">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>

      <ModalShow show={show} handleClose={handleClose} data={invoicedata} />
    </div>
  );
};

export default PurchaseList;
