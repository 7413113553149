import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useState } from "react";
import Api1 from "../services/Api1";
import Api2 from "../services/Api2";

const CounterSaleReport = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { data: saledata, refetch: refetchsale } = useQuery(
    ["saledata"],
    () => Api2.get(`/welcome/counterSalesReport/${from}/${to}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,

      enabled: false,

      select: (res) => res.data,
    }
  );

  const {
    data,
    isLoading,
    refetch: refetchreport,
    error: inerror,
  } = useQuery(
    ["reportdata"],
    () => Api1.get(`get-purchase-report/${from}/${to}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const handleReport = () => {
    refetchsale();
    refetchreport();
  };

  console.log(saledata && saledata);

  // console.log("gst",gst)
  // console.log("saleorderproducts",saleorders)

  // var gst12=0
  // gst&&gst.forEach((item)=>{
  // if(item.gst=='12')
  // {
  // gst12=gst12+parseInt(item.totalamt)*parseInt(item.gst)/100
  // }

  // })

  // console.log("gst 12",gst12)

  var sale_total = 0;
  saledata &&
    saledata?.sales_orders.forEach((item) => {
      sale_total += parseInt(item.total);
    });
  var gst_total =
    parseInt(data?.gst0total) +
    parseInt(data?.gst5total) +
    parseInt(data?.gst12total) +
    parseInt(data?.gst18total);
  var fulltotal = 0;
  fulltotal = sale_total + gst_total;
  return (
    <div className="mainBody mt-5">
      <div className="innerPageSection">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Sales Report</h3>
                  </div>

                  <div className="card-body">
                    <div className="form-group col-md-12">
                      <input
                        type="date"
                        onChange={(e) => setFrom(e.target.value)}
                        name="from"
                        className="rr"
                      />
                      <input
                        type="date"
                        onChange={(e) => setTo(e.target.value)}
                        name="to"
                        className="rr"
                      />
                      <button
                        name="submit"
                        className="btn btn-primary"
                        onClick={() => handleReport()}
                        style={{ marginTop: "-7px" }}
                      >
                        Get sales
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        id="convert"
                        className="btn btn-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        Convert to ExcelSheet
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Sales Report</h3>
                  </div>

                  <div className="card-body">
                    <table className="table table-bordered" id="mytable">
                      <tr>
                        <th colspan="2">Sales Local</th>
                      </tr>
                      <tbody>
                        <tr>
                          <td>GST Local Sales 12%</td>
                          <td>{data?.gst12total}</td>
                        </tr>
                        <tr>
                          <td>GST Local Sales 18%</td>
                          <td>{data?.gst18total}</td>
                        </tr>
                        <tr>
                          <td>GST Local Sales 5%</td>
                          <td>{data?.gst5total}</td>
                        </tr>
                        <tr>
                          <td>GST Local Sales 0% Free</td>
                          <td>{data?.gst0total}</td>
                        </tr>
                        <tr>
                          <td style={{ background: "#333", color: "#fff" }}>
                            Sale Amount
                          </td>
                          <td style={{ background: "#333", color: "#fff" }}>
                            {sale_total ?? 0}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#333", color: "#fff" }}>
                            Total GST
                          </td>
                          <td style={{ background: "#333", color: "#fff" }}>
                            {gst_total ?? 0}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#333", color: "#fff" }}>
                            Total Amount
                          </td>
                          <td style={{ background: "#333", color: "#fff" }}>
                            {fulltotal ?? 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterSaleReport;
