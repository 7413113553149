import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="logo">
                <Link to="/dashboard">
                  <a>
                    <img src="logo.png" className="img-fluid" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-md-9">
              <div className="settingDropdown">
                <button className="dropbtn">
                  <img src="/icons/profile.png" alt="" />
                  {/* <img src="assets/images/icons/profile.png"> */}
                </button>
                <ul className="dropdown-content">
                  <li>
                    <a routerLink="/change-password">
                      <i className="fa fa-key" aria-hidden="true"></i>Change
                      Password
                    </a>
                  </li>
                  <li>
                    <a onClick={() => navigate("/")}>
                      <i className="fas fa-sign-out-alt"></i>Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </header>

      <div
        id="mySidenav"
        className="sidenav"
        style={{ width: "15%", fontSize: 15, fontWeight: 500 }}
      >
        <a href="" className="closebtn" onClick="closeNav()">
          ×
        </a>

        <ul className="sideNavMenu">
          <li>
            <Link to="/dashboard">
              <a>
                <i className="fa fa-home"></i>Dashboard
              </a>
            </Link>
          </li>
          <li>
            <Link to="/purchaselist">
              <a>
                <i className="fa fa-home"></i>Purchase
              </a>
            </Link>
          </li>
          <li>
            <Link to="/countersale">
              <a>
                <i className="fa fa-book"></i>Counter Sale
              </a>
            </Link>
          </li>
          <li>
            <Link to="/purchasereport">
              <a>
                <i className="fa fa-book"></i>Purchase report
              </a>
            </Link>
          </li>
          <li>
            <Link to="/creditbill">
              <a>
                <i className="fa fa-book"></i>Credit Billing
              </a>
            </Link>
          </li>
          <li>
            <Link to="/countersalereport">
              <a>
                <i className="fa fa-book"></i>Counter Sale Report
              </a>
            </Link>
          </li>
          <li>
            <Link to="/productwisereport">
              <a>
                <i className="fa fa-book"></i>Product Wise Report
              </a>
            </Link>
          </li>
          <li>
            <Link to="/productwisedayreport">
              <a>
                <i className="fa fa-book"></i>Product Wise Day Report
              </a>
            </Link>
          </li>
          <li>
            <Link to="/invoicereport">
              <a>
                <i className="fa fa-book"></i>Invoice Report
              </a>
            </Link>
          </li>
          <li>
            <Link to="/orders">
              <a>
                <i className="fa fa-book"></i>Orders B2B
              </a>
            </Link>
          </li>
          <li>
            <Link to="/ordersretailer">
              <a>
                <i className="fa fa-book"></i>Orders Retailers
              </a>
            </Link>
          </li>
        </ul>
      </div>

      <div className="main_menu" onClick="openNav()">
        <img src="/icons/menu.png" alt="" />
        {/* <img src="assets/images/icons/menu.png"> */}
      </div>
    </div>
  );
};

export default Header;
