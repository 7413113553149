import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Api2 from "../services/Api2";

const CountersaleReturn = () => {
  const [show, setShow] = useState(false);
  const [oid, setOid] = useState();
  const [ono, setOno] = useState();

  const [txt, setTxt] = useState();
  const [mrp, setMrp] = useState(0);
  const [qty, setQty] = useState(0);
  const [batch, setBatch] = useState();
  const [expiry, setExpiry] = useState();
  const [total, setTotal] = useState();
  const [proName, setProName] = useState();
  const [productId, setProductId] = useState();
  const [disc, setDisc] = useState();
  const [pid, setPid] = useState("");

  const { data: countersalereturn } = useQuery(
    ["countersalereturn"],
    () => Api2.get(`/counter-sale-return`),
    {
      //   retry: false,
      select: (res) => res.data,
    }
  );

  const { data: customerdata } = useQuery(
    ["customerdata"],
    () => Api2.get(`/customers`),
    {
      //   retry: false,
      select: (res) => res.data,
    }
  );

  const { data: counterdatabyname } = useQuery(
    ["customerdatabyname", oid],
    () => Api2.get(`/counter-sale-return-byname/${oid}`),
    {
      //   retry: false,
      select: (res) => res.data,
    }
  );

  const { data: prodata, refetch } = useQuery(
    ["prodata"],
    () =>
      Api2.get(
        `/welcome/counterSaleDetailSubmitReturn/${ono}/${productId}/${proName}/${mrp}/${total}/${qty}/${batch}/${expiry}/${disc}/${pid}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );

  const { data: searchdata } = useQuery(
    ["searchdata", txt],
    () => Api2.get(`/welcome/getProducts/${txt}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );

  const handleCal = (e) => {
    setQty(e.target.value);
    var tamt = parseInt(e.target.value) * mrp;
    console.log(tamt);
    setTotal(tamt);
  };

  const handleProducts = (item) => {
    setMrp(parseInt(item.mrp));
    setProName(item.name);
    setProductId(item.id);
  };

  const handleEye = (item) => {
    setOid(item.order_id);
    setShow(true);
    setOno(item.order_no);
  };

  // console.log(countersalereturn&&countersalereturn)

  return (
    <div>
      <div className="content">
        <div className="container-fluid">
          {show ? (
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Counter Sale Item Return</h3>
                  </div>
                  <div
                    className="card-body"
                    style={{ width: "70%", position: "relative", left: 250 }}
                  >
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label>
                          Customer Name :{" "}
                          {counterdatabyname?.customer?.length &&
                            counterdatabyname?.customer[0]?.fullname}{" "}
                        </label>
                      </div>
                      <div className="form-group col-md-4">
                        <label>
                          Invoice No.:{" "}
                          {counterdatabyname?.customer?.length &&
                            counterdatabyname?.sale[0].invoice_no}{" "}
                        </label>
                      </div>
                      <div className="form-group col-md-4">
                        <label>
                          Invoice Date :{" "}
                          {counterdatabyname?.customer?.length &&
                            counterdatabyname?.sale[0].invoicedate}{" "}
                        </label>
                      </div>
                      <div className="form-group col-md-5">
                        <label>Product Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="itemname"
                          id="itemname"
                          onChange={(e) => setTxt(e.target.value)}
                        />
                        <div className="row" id="products">
                          {searchdata &&
                            searchdata.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => handleProducts(item)}
                                >
                                  {item.name}
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <div className="form-group col-md-2">
                        <label>MRP</label>
                        <input
                          type="number"
                          className="form-control"
                          name="mrp"
                          id="mrp"
                          step="any"
                          value={mrp}
                          onChange={(e) => setMrp(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group col-md-2">
                        <label>Discount</label>
                        <input
                          type="number"
                          className="form-control"
                          step="any"
                          value={disc}
                          onChange={(e) => setDisc(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group col-md-1">
                        <label>Quantity</label>
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          name="qty"
                          id="qty"
                          onChange={(e) => handleCal(e)}
                          value={qty}
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Batch No.</label>
                        <input
                          type="text"
                          className="form-control"
                          name="batch"
                          id="batch"
                          value={batch}
                          onChange={(e) => setBatch(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Expiry</label>
                        <input
                          type="text"
                          className="form-control"
                          name="expiry"
                          id="expiry"
                          maxlength="5"
                          value={expiry}
                          onChange={(e) => setExpiry(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <label>Total</label>
                        <input
                          type="number"
                          className="form-control"
                          name="total"
                          id="total"
                          value={total}
                          readOnly
                          required
                        />
                      </div>
                      <div className="form-group col-md-1">
                        <button
                          name="submit"
                          className="btn btn-success mgTop25"
                          onClick={() => refetch()}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card"
                  id="itemTable"
                  style={{ width: "80%", position: "relative", left: 250 }}
                >
                  <div className="card-header">
                    <h3 className="card-title">Purchase Items</h3>
                  </div>
                  <div className="card-body">
                    <table id="" className="tbl">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Product Name</th>
                          <th>Batch</th>
                          <th>Expiry</th>
                          <th>Mrp</th>
                          <th>Qty</th>
                          <th>Total</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prodata &&
                          prodata.dataa.sale.length &&
                          prodata?.dataa.sale.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td width="30px">{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.batch}</td>
                                <td>{item.expiry}</td>
                                <td width="120px">{item.mrp}</td>
                                <td width="70px">{item.quantity}</td>
                                <td width="120px">{item.total}</td>
                                <td width="80px" className="actionBtn">
                                  <a>
                                    <i className="fas fa-edit"></i>
                                  </a>
                                  <a>
                                    <i className="fas fa-trash-alt"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}

                        <tr>
                          <td className="labelFont" colspan="6">
                            <b>SubTotal</b>
                          </td>
                          <td>
                            <b>
                              {prodata && prodata.dataa.sale.length
                                ? prodata.dataa.sale[0].total
                                : 0}
                            </b>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="4" className="labelFont">
                            <b>Discount Total</b>
                          </td>
                          <td colspan="2" className="discountTbl" width="200px">
                            <input
                              className="form-control"
                              type="number"
                              name="discount"
                              id="discount"
                              step="any"
                            />
                            <input
                              className="btn btn-sm btn-success"
                              type="submit"
                              name="submit"
                              value="Save"
                            />
                          </td>
                          <td>
                            <b></b>
                          </td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>
                            <b>GST</b>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <b></b>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="labelFont" colspan="6">
                            <b>Grand Total</b>
                          </td>
                          <td>
                            <b></b>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="wrapper" style={{ marginTop: "80px" }}>
                <div className="content-wrapper">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Counter Sale</h3>
                      <a
                        href="counter-sale-payment-page"
                        className="btn btn-primary"
                        style={{ float: "right", marginRight: "5px" }}
                      >
                        payment
                      </a>
                      <a
                        href="counter-sale-return"
                        className="btn btn-primary"
                        style={{ float: "right", marginRight: "5px" }}
                      >
                        Return Sale
                      </a>
                      <Link to="/countersalereturnadd">
                        <a
                          href="#"
                          className="btn btn-primary"
                          style={{ float: "right", marginRight: "5px" }}
                        >
                          Add Return
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="wrapper mt-2">
                <div className="content-wrapper">
                  <div className="card-body">
                    <table id="example1" className="tbl">
                      <thead>
                        <tr>
                          <th width="20px">S.No.</th>
                          <th>Invoice Date</th>
                          <th>Invoice No.</th>
                          <th>Customer Name</th>
                          <th>Payment Mode</th>
                          <th>Total Amount</th>
                          <th>Entry Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {countersalereturn &&
                          countersalereturn?.counterSales.map((item, index) => {
                            return (
                              <tr>
                                <td align="center">{index + 1} </td>
                                <td>{item.invoicedate}</td>
                                <td> {item.invoice_no} </td>
                                <td>
                                  {" "}
                                  <span>
                                    {customerdata &&
                                      customerdata.customers[
                                        customerdata?.customers.findIndex(
                                          (citem) =>
                                            citem.id == item.customer_id
                                        )
                                      ].fullname}
                                  </span>
                                </td>
                                <td>{item.paymentmode}</td>
                                <td> {item.total} </td>
                                <td> {item.orderdate} </td>
                                <td className="actionBtn">
                                  <a onClick={() => handleEye(item)}>
                                    {" "}
                                    <i className="fas fa-eye"></i>
                                  </a>
                                  <a target="_blank">
                                    <i className="fas fa-print"></i>
                                  </a>
                                  <a>
                                    <i className="fas fa-trash-alt"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountersaleReturn;
