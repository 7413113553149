import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Api2 from "../services/Api2";

const ModalShow = (props) => {

const handlePrint=()=>{
  
}


  return (
    <Modal
      show={props.show}
      dialogClassName="modal-100w"
      onHide={() => props.handleClose()}
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title>Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe id="ifmcontentstoprint" style={{ display: "none" }}></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handlePrint}>
          print
        </Button>

        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={props.handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CounterSale = () => {
  const [oid, setOid] = useState();
  const [ono, setOno] = useState();
  const [show, setShow] = useState(false);
  const [txt, setTxt] = useState();
  const [mrp, setMrp] = useState(0);
  const [qty, setQty] = useState(0);
  const [batch, setBatch] = useState();
  const [expiry, setExpiry] = useState();
  const [total, setTotal] = useState();
  const [proName, setProName] = useState();
  const [gtotal, setGtotal] = useState();
  const [productId, setProductId] = useState();
  const [disc, setDisc] = useState(0);
  const [discAmt, setDiscAmt] = useState(0);
  const [id, setId] = useState();
  const [update, setUpdate] = useState(false);
  const [modalShow, setModalShow] = useState(false);


  const location = useLocation();
  const { data } = useQuery(
    ["countersaledata"],
    () => Api2.get(`/counter-sale`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      //   retry: false,
      select: (res) => res.data,
    }
  );

  const { data: customerdata } = useQuery(
    ["customerdata"],
    () => Api2.get(`/customers`),
    {
      //   retry: false,
      select: (res) => res.data,
    }
  );
  const { data: counterdatabyid, refetch: refetchcounterdatabyid } = useQuery(
    ["counterdatabyid", oid],
    () => Api2.get(`/counter-sale/${oid}`),
    {
      //   retry: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );
  const { data: searchdata } = useQuery(
    ["searchdata", txt],
    () => Api2.get(`/welcome/getProducts/${txt}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );

  const { data: prodata, refetch } = useQuery(
    ["prodata"],
    () =>
      Api2.get(
        `/welcome/counterSaleDetailEdit/${id}/${proName}/${mrp}/${disc}/${total}/${batch}/${expiry}/${qty}/${productId}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );


  const { data: invoicedata, refetch: refetchinvoice } = useQuery(
    ["counterinvoice"],
    () => Api2.get(`welcome/counterInvoice/${id}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );




  const handleCal = (e) => {
    setQty(e.target.value);
    var tamt = parseInt(e.target.value) * mrp;
    console.log(tamt);
    setTotal(tamt);
  };

  const handleProducts = (item) => {
    setMrp(parseInt(item.mrp));
    setProName(item.name);
    setProductId(item.id);
    setTxt(item.name);
  };

  const handleGetCustomerDetails = (item) => {
    setOid(item.order_id);
    setShow(!show);
    setOno(item.order_no);
  };

  const handleClose = () => {
    setModalShow(false);
  };
  useEffect(() => {
    refetchinvoice();
  }, [id]);


  var subtotal = 0;
  counterdatabyid &&
    counterdatabyid.saleItems.forEach((item) => {
      subtotal += parseInt(item.total);
    });

  useEffect(() => {
    if (counterdatabyid) {
      setGtotal(counterdatabyid?.sale[0].total);
      setDisc(parseInt(counterdatabyid?.sale[0].discount));
    }
  }, [counterdatabyid]);

  const handleDisc = (value) => {
    if (!value) {
      setDiscAmt(0);
      setGtotal(counterdatabyid?.sale[0].total);
      setDisc(value);
    } else {
      setDisc(value);
      var discamt = (subtotal * parseInt(value)) / 100;
      setDiscAmt(discamt);
      setGtotal(subtotal - discamt);
    }
  };

  const handleRefetch = () => {
    var tempExpiry = expiry.replace("/", "-");
    setExpiry(tempExpiry);
    setTimeout(() => {
      refetch();
    }, 1000);
    setTimeout(() => {
      refetchcounterdatabyid();
    }, 2000);
    setTimeout(() => {
      setTxt("");
      setMrp("");
      setBatch("");
      setExpiry("");
      setTotal("");
      setQty("");
    }, 4000);
  };

  const handleEdit = (item) => {
    console.log(item);
    setUpdate(!update);
    setProductId(item.product_id);
    setBatch(item.batch);
    setExpiry(item.expiry);
    setMrp(item.price);
    setQty(item.quantity);
    setTotal(item.total);
    setId(item.id);
    setProName(item.name);
  };

  return (
    <div className="mainBody mt-5">
      <div className="innerPageSection">
        <div className="content">
          <div className="container-fluid">
            {show ? (
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Counter Sale Item Add</h3>
                        </div>
                        <div
                          className="card-body"
                          style={{
                            width: "90%",
                            position: "relative",
                          }}
                        >
                          <div className="row">
                            <div className="form-group col-md-4">
                              <label>
                                Customer Name :{" "}
                                {counterdatabyid &&
                                  counterdatabyid.customer[0].fullname}{" "}
                              </label>
                            </div>
                            <div className="form-group col-md-4">
                              <label>
                                Invoice No.:{" "}
                                {counterdatabyid &&
                                  counterdatabyid.sale[0].invoice_no}{" "}
                              </label>
                            </div>
                            <div className="form-group col-md-4">
                              <label>
                                Invoice Date :{" "}
                                {counterdatabyid &&
                                  counterdatabyid.sale[0].invoicedate}{" "}
                              </label>
                            </div>
                            <div className="form-group col-md-5">
                              <label>Product Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="itemname"
                                id="itemname"
                                onChange={(e) => setTxt(e.target.value)}
                              />
                              <div className="row" id="products">
                                {searchdata &&
                                  searchdata.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() => handleProducts(item)}
                                      >
                                        {item.name}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>

                            <div className="form-group col-md-2">
                              <label>MRP</label>
                              <input
                                type="number"
                                className="form-control"
                                name="mrp"
                                id="mrp"
                                step="any"
                                value={mrp}
                                onChange={(e) => setMrp(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group col-md-1">
                              <label>Quantity</label>
                              <input
                                type="number"
                                step="any"
                                className="form-control"
                                name="qty"
                                id="qty"
                                onChange={(e) => handleCal(e)}
                                value={qty}
                                required
                              />
                            </div>
                            <div className="form-group col-md-1">
                              <label>Batch No.</label>
                              <input
                                type="text"
                                className="form-control"
                                name="batch"
                                id="batch"
                                value={batch}
                                onChange={(e) => setBatch(e.target.value)}
                              />
                            </div>
                            <div className="form-group col-md-1">
                              <label>Expiry</label>
                              <input
                                type="text"
                                className="form-control"
                                name="expiry"
                                id="expiry"
                                maxlength="5"
                                value={expiry}
                                onChange={(e) => setExpiry(e.target.value)}
                              />
                            </div>
                            <div className="form-group col-md-1">
                              <label>Total</label>
                              <input
                                type="number"
                                className="form-control"
                                name="total"
                                id="total"
                                value={total}
                                readOnly
                                required
                              />
                            </div>
                            <div className="form-group col-md-1">
                              <button
                                name="submit"
                                className="btn btn-success mm"
                                onClick={() => handleRefetch()}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="card"
                        id="itemTable"
                        style={{
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <div className="card-header">
                          <h3 className="card-title">Purchase Items</h3>
                        </div>
                        <div className="card-body">
                          <table id="" className="tbl">
                            <thead>
                              <tr>
                                <th>S.No.</th>
                                <th>Product Name</th>
                                <th>Batch</th>
                                <th>Expiry</th>
                                <th>Mrp</th>
                                <th>Qty</th>
                                <th>Total</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {counterdatabyid &&
                                counterdatabyid?.saleItems.map(
                                  (item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td width="30px">{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.batch}</td>
                                        <td>{item.expiry}</td>
                                        <td width="120px">{item.mrp}</td>
                                        <td width="70px">{item.quantity}</td>
                                        <td width="120px">{item.total}</td>
                                        <td width="80px" className="actionBtn">
                                          <a>
                                            <i
                                              className="fas fa-edit"
                                              onClick={() => handleEdit(item)}
                                            ></i>
                                          </a>
                                          <a>
                                            <i className="fas fa-trash-alt"></i>
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}

                              <tr>
                                <td className="labelFont" colspan="6">
                                  <b>SubTotal</b>
                                </td>
                                <td>
                                  <b>{subtotal}</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colspan="4" className="labelFont">
                                  <b>Discount Total</b>
                                </td>
                                <td
                                  colspan="2"
                                  className="discountTbl"
                                  width="200px"
                                >
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="discount"
                                    id="discount"
                                    step="any"
                                    value={disc}
                                    onChange={(e) => handleDisc(e.target.value)}
                                  />
                                  <input
                                    className="btn btn-sm btn-success mm2"
                                    type="submit"
                                    name="submit"
                                    value="Save"
                                  />
                                </td>
                                <td>
                                  <b>{discAmt}</b>
                                </td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>
                                  <b>GST</b>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <b></b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td className="labelFont" colspan="6">
                                  <b>Grand Total</b>
                                </td>
                                <td>
                                  <b>{gtotal}</b>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {" "}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title mt-2">Counter Sale</h3>
                        <a
                          href="counter-sale-payment-page"
                          className="btn btn-primary"
                          style={{ float: "right", marginRight: "5px" }}
                        >
                          payment
                        </a>
                        <Link to="/countersalereturn">
                          <a
                            href="#"
                            className="btn btn-primary"
                            style={{ float: "right", marginRight: "5px" }}
                          >
                            Return Sale
                          </a>
                        </Link>

                        <Link to="/countersaleadd">
                          <a
                            href="#"
                            className="btn btn-primary"
                            style={{ float: "right", marginRight: "5px" }}
                          >
                            Add Sale
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="col-md-12 ">
                    <div className="card-body">
                      <table id="example1" className="tbl">
                        <thead>
                          <tr>
                            <th width="20px">S.No.</th>
                            <th>Invoice Date</th>
                            <th>Invoice No.</th>
                            <th>Customer Name</th>
                            <th>Payment Mode</th>
                            <th>Total Amount</th>
                            <th>Entry Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data?.counterSales &&
                            data?.counterSales.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td align="center">{index + 1}</td>
                                  <td>{item.invoicedate}</td>
                                  <td>{item.invoice_no}</td>
                                  <td>
                                    <span>
                                      {customerdata &&
                                        customerdata?.customers.find(
                                          (cust) => cust.id == item.customer_id
                                        )?.fullname}
                                    </span>
                                  </td>
                                  <td>{item.paymentmode}</td>
                                  <td>{item.total}</td>
                                  <td>{item.orderdate}</td>
                                  <td className="actionBtn">
                                    <a
                                      onClick={() =>
                                        handleGetCustomerDetails(item)
                                      }
                                    >
                                      <i className="fas fa-eye"></i>
                                    </a>
                                    <a>
                                      <i className="fas fa-print"></i>
                                    </a>
                                    <a>
                                      <i className="fas fa-trash-alt"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterSale;
