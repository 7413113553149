import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import Api1 from "../services/Api1";
import Api2 from "../services/Api2";

const PurchaseInvoiceSearch = () => {
  const [partyid, setPartyId] = useState();
  const [invoiceno, setInvoiceno] = useState();
  const [show, setShow] = useState(false);
  const [text, setText] = useState();
  const [packing, setPacking] = useState("");
  const [mrp, setMrp] = useState();
  const [rate, setRate] = useState();
  const [gst, setGst] = useState();
  const [id, setId] = useState();
  const [expiry, setExpiry] = useState();
  const [batch, setBatch] = useState();
  const [qty, setQty] = useState();
  const [free, setFreeQty] = useState();
  const [hsn, setHsn] = useState();
  const [purchaseid, setPurchaseId] = useState();
  const [products, setProducts] = useState();
  const [returnqty, setReturnqty] = useState();
  const [returnid, setReturnid] = useState();

  const { data } = useQuery(
    ["purchasedata"],
    () => Api1.get("purchase-supplier"),
    {
      select: (res) => res.data,
    }
  );

  const { data: indata } = useQuery(
    ["invoicedata", partyid],
    () => Api2.get(`welcome/getPurchaseInvoices/${partyid}`),
    {
      select: (res) => res.data,
    }
  );

  const { data: submitdata, refetch: refetchsubmit } = useQuery(
    ["submitdata"],
    () => Api2.get(`welcome/purchaseReturnInvoiceSearchId/${invoiceno}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );

  const { data: searchdata } = useQuery(
    ["searchdata", text],
    () => Api1.get(`search-products/${text}`),
    {
      select: (res) => res.data,
    }
  );

  const { data: prodata, refetch } = useQuery(
    ["prodata"],
    () =>
      Api2.get(
        `welcome/purchaseDetailReturnSubmit/${purchaseid}/${id}/${hsn}/${packing}/${batch}/${expiry}/${qty}/${free}/${rate}/${mrp}/${gst}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: returndata, refetch: returnrefetch } = useQuery(
    ["returndata"],
    () => Api2.get(`welcome/returnQuantity/${returnqty}/${returnid}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  useEffect(() => {
    if (prodata) {
      setProducts(prodata);
    }
  }, [prodata]);

  useEffect(() => {
    if (submitdata) {
      setProducts(submitdata);
    }
  }, [submitdata]);

  console.log(data);

  var sortdata = data?.sort((a, b) =>
    a.partyname > b.partyname ? 1 : b.partyname > a.partyname ? -1 : 0
  );

  const handleInvoiceSubmit = () => {
    refetchsubmit();
    setShow(true);
  };

  const handleReturn = (id) => {
    setReturnid(id);
    setTimeout(() => {
      returnrefetch();
      refetchsubmit();
    }, 1000);
  };

  const handleAddProduct = () => {
    var tempdate = expiry?.replace("/", "-");
    var tempPacking = packing.replace("'", "-");
    setPacking(tempPacking);
    setExpiry(tempdate);
    setPurchaseId(submitdata?.purchase_order[0]?.id);

    setTimeout(() => {
      refetch();
    }, 1000);
    setTimeout(() => {
      setMrp("");
      setRate("");
      setHsn("");
      setBatch("");
      setQty("");
      setFreeQty("");
      setText("");
      setPacking("");
      setExpiry("");
      setQty("");
      setGst("");
    }, 5000);
  };

  const handleSetData = (item) => {
    setHsn(item.hsncode);
    setPacking(item.packing);
    setMrp(item.mrp);
    setRate(item.wholesaleprice);
    setGst(item.gst);
    setId(item.id);
    setText(item.name);
    setFreeQty(0);
  };

  return (
    <React.Fragment>
      {show ? (
        <div>
          <div className="wrapper">
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Purchase Return</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <a href="dashboard">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="purchase">Purchase</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Purchase return
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Purchase Return</h3>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-md-4">
                            <label>Party Name :</label>
                            {submitdata?.supplier[0]?.partyname}
                          </div>
                          <div className="form-group col-md-4">
                            <label>Invoice No.:</label>
                            {submitdata?.purchase_order[0]?.invoiceno}
                          </div>
                          <div className="form-group col-md-4">
                            <label>Invoice Date :</label>
                            {submitdata?.purchase_order[0]?.purchasedate}
                          </div>
                          <div className="form-group col-md-2">
                            <label>Product Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="itemname"
                              id="itemname"
                              autocomplete="off"
                              onChange={(e) => setText(e.target.value)}
                            />
                            <div className="row" id="products">
                              {searchdata &&
                                searchdata.map((item, index) => {
                                  return (
                                    <div
                                      key={item.id.toString()}
                                      tabIndex={0}
                                      onClick={() => handleSetData(item)}
                                    >
                                      <div>
                                        {" "}
                                        {item.name} {"  "} {item.packing}{" "}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="form-group col-md-1">
                            <label>HSN Code</label>
                            <input
                              type="text"
                              className="form-control"
                              name="hsn"
                              id="hsn"
                              value={hsn}
                              onChange={(e) => setHsn(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>Packing</label>
                            <input
                              type="text"
                              className="form-control"
                              name="pack"
                              id="pack"
                              value={packing}
                              onChange={(e) => setPacking(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>Expiry</label>
                            <input
                              type="text"
                              className="form-control"
                              name="exp"
                              value={expiry}
                              onChange={(e) => setExpiry(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>Batch No.</label>
                            <input
                              type="text"
                              className="form-control"
                              name="batch"
                              value={batch}
                              onChange={(e) => setBatch(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>MRP</label>
                            <input
                              type="number"
                              className="form-control"
                              name="mrp"
                              id="mrp"
                              step="any"
                              value={mrp}
                              onChange={(e) => setMrp(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>Rate</label>
                            <input
                              type="number"
                              className="form-control"
                              name="rate"
                              step="any"
                              id="whprice"
                              value={rate}
                              onChange={(e) => setRate(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>Quantity</label>
                            <input
                              type="number"
                              className="form-control"
                              step="any"
                              name="qty"
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label>Free Quantity</label>
                            <input
                              type="number"
                              className="form-control"
                              name="fqty"
                              step="any"
                              value={free}
                              onChange={(e) => setFreeQty(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>GST</label>
                            <input
                              type="number"
                              className="form-control"
                              name="gst"
                              id="gst"
                              value={gst}
                              onChange={(e) => setGst(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-4 text-start">
                            <button
                              name="submit"
                              className="btn btn-success"
                              onClick={() => handleAddProduct()}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper">
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card" id="itemTable">
                      <div className="card-header">
                        <h3 className="card-title">Purchase Items</h3>
                      </div>
                      <div className="card-body">
                        <table id="" className="tbl">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Product Name</th>
                              <th>HSN Code</th>
                              <th>Packing</th>
                              <th>Batch</th>
                              <th>Expiry</th>
                              <th>Qty</th>
                              <th>Free Qty</th>
                              <th>Total Qty</th>
                              <th>Rate</th>
                              <th>Mrp</th>
                              <th>GST</th>
                              <th>Total</th>
                              <th>Returned Quantity</th>
                              <th>Available Quantity</th>
                              <th>Return Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products?.purchase_products.map((item, index) => {
                              return (
                                <tr>
                                  <td width="30px">{index + 1}</td>
                                  <td>
                                    {
                                      products?.products[index]?.find(
                                        (pi) => pi.id == item.productid
                                      )?.name
                                    }
                                  </td>
                                  <td width="70px">{item.hsncode}</td>
                                  <td width="120px">{item.packing}</td>
                                  <td width="30px">{item.batch}</td>
                                  <td width="30px">{item.expiry}</td>
                                  <td width="30px">{item.quantity}</td>
                                  <td width="60px">{item.freeqty}</td>
                                  <td width="65px">{item.totalqty}</td>
                                  <td width="60px">{item.wholesaleprice}</td>
                                  <td width="60px">{item.mrp}</td>
                                  <td width="60px">{item.gst}%</td>
                                  <td width="60px">{item.totalamt}</td>
                                  <td>{item.returned_qty}</td>
                                  <td>{item.available}</td>
                                  <td>
                                    <input
                                      type="number"
                                      min="0"
                                      onChange={(e) =>
                                        setReturnqty(e.target.value)
                                      }
                                    />
                                    <button
                                      name="return"
                                      className="btn btn-primary returnBtn"
                                      onClick={() => handleReturn(item.id)}
                                    >
                                      Return
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td
                                className="labelFont text-start p1"
                                colspan="14"
                              >
                                <b>SubTotal</b>
                              </td>
                              <td>
                                <b></b>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td
                                className="labelFont text-start p1"
                                colspan="12"
                              >
                                <b>Discount Total</b>
                              </td>
                              <td colspan="3" className="discountTbl"></td>
                              <td>
                                <b>%</b>
                              </td>
                              <td></td>
                            </tr>

                            <tr>
                              <td
                                className="labelFont text-start p1"
                                colspan="14"
                              >
                                <b>GST</b>
                              </td>
                              <td>
                                <b></b>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td
                                className="labelFont text-start p1"
                                colspan="14"
                              >
                                <b>Grand Total</b>
                              </td>
                              <td>
                                <b></b>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="container-fluid"
            style={{ marginTop: 100, width: "50%", margin: "auto" }}
          >
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Purchase Return</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Purchase</a>
                  </li>
                  <li className="breadcrumb-item active">Purchase return</li>
                </ol>
              </div>
            </div>
          </div>

          <section
            className="content mt-4"
            style={{ width: "50%", margin: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Purchase Return</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Select Party</label>
                          <select
                            name="category"
                            className="form-control category_id"
                            id="category_id"
                            required
                            value={partyid}
                            onChange={(e) => setPartyId(e.target.value)}
                          >
                            <option value="">Select</option>
                            {sortdata &&
                              sortdata.map((item) => {
                                return (
                                  <option value={item.id}>
                                    {item.partyname}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label>Invoice No.</label>
                          <select
                            name="invoice"
                            className="form-control subcategory_id"
                            id="subcategory_id"
                            onChange={(e) => setInvoiceno(e.target.value)}
                          >
                            <option value="">Select</option>
                            {indata &&
                              indata.map((item) => {
                                return <option>{item.invoiceno}</option>;
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-12 text-center">
                        <button
                          name="submit"
                          className="btn btn-success"
                          onClick={() => handleInvoiceSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </React.Fragment>
  );
};

export default PurchaseInvoiceSearch;
