import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Api2 from "../services/Api2";

const CounterSaleAdd = () => {
  const [show, setShow] = useState(false);
  const [cmobile, setCmobile] = useState();
  const [cdate, setCdate] = useState(moment().format("YYYY-MM-DD"));
  const [invoice, setInvoice] = useState();
  const [cname, setCname] = useState();
  const [drName, setDrname] = useState();
  const [address, setAddress] = useState();
  const [oid, setOid] = useState();
  const [txt, setTxt] = useState();
  const [mrp, setMrp] = useState();
  const [batch, setBatch] = useState();
  const [qty, setQty] = useState();
  const [expiry, setExpiry] = useState();
  const [proName, setProName] = useState();
  const [productId, setProductId] = useState();
  const [total, setTotal] = useState();
  const [ono, setOno] = useState();
  const [disc, setDisc] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState();
  const [products, setProducts] = useState();
  const [backspace, setBackspace] = useState(false);
  const [cursor, setCursor] = useState(0);

  const batchref = useRef(0);
  const pfocus = useRef();
  // const cursor = useRef(null);

  const navigate = useNavigate();

  const { data, refetch } = useQuery(
    ["customersaledata"],
    () =>
      Api2.get(
        `/welcome/counterSaleDetailCustSubmit/${cmobile}/${cname}/${drName}/${address}/${cdate}/${invoice}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: searchdata } = useQuery(
    ["searchdata", txt],
    () => Api2.get(`/welcome/getProducts/${txt}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled:false,

      select: (res) => res.data,
    }
  );
  const { data: searchdatadynamic } = useQuery(
    ["searchdynamic", productId],
    () => Api2.get(`/welcome/getDynamicProducts/${productId}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      // retry: false,
      // enabled:false,

      select: (res) => res.data,
    }
  );

  const { data: prodata, refetch: refetchproducts } = useQuery(
    ["prodata"],
    () =>
      Api2.get(
        `/welcome/counterSaleDetailSubmit/${oid}/${productId}/${proName}/${mrp}/${total}/${qty}/${
          searchdatadynamic.batch ? searchdatadynamic.batch : 0
        }/${
          searchdatadynamic.expiry
            ? searchdatadynamic.expiry.replace("/", "-")
            : 0
        }`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: invoicedata } = useQuery(
    ["invoice"],
    () => Api2.get("/welcome/getCurrentInvoice"),
    {
      refetchOnWindowFocus: false,

      refetchOnMount: false,
      refetchOnReconnect: false,
      select: (res) => {
        return res.data;
      },
    }
  );

  const { data: updata, refetch: refetchupdata } = useQuery(
    ["updata"],
    () =>
      Api2.get(`/welcome/updateSaleDiscount/${subtotal}/${discount}/${oid}`),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: false,
      select: (res) => {
        return res.data;
      },
    }
  );

  // const { data: proupdata, refetch: refetchproup } = useQuery(
  //   ["proupdata"],
  //   () =>
  //     Api2.get(
  //       `/welcome/counterSaleDetailEdit/${id}/${proName}/${mrp}/${disc}/${total}/${batch}/${expiry}/${qty}/${productId}/${ono}`
  //     ),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     select: (res) => res.data,
  //   }
  // );

  useEffect(() => {
    setTxt("");
  }, []);

  useEffect(() => {
    if (prodata) {
      setProducts(prodata);
    }
  }, [prodata]);

  // useEffect(() => {
  //   if (proupdata) {
  //     setProducts(proupdata);
  //   }
  // }, [prodata]);

  useEffect(() => {
    if (invoicedata?.invoice) {
      setInvoice(invoicedata.invoice);
    }
  }, invoicedata);

  const handleProducts = (item) => {
    setMrp(parseInt(item.mrp));
    setProName(item.name);
    setTxt(item.name);
    setProductId(item.id);
    setOno(data && data?.dataa?.sale[0].order_no);
    setOid(data && data?.dataa?.sale[0].order_id);
    console.log(searchdatadynamic);
    batchref.current += 1;
  };

  const handleCal = (e) => {
    setQty(e.target.value);
    var tamt = parseInt(e.target.value) * mrp;
    console.log(tamt);
    setTotal(tamt);
  };

  const handleSubmit = () => {
    setShow(true);
    refetch();
  };

  const handleSubmit2 = () => {
    setTimeout(() => {
      refetchproducts();
    }, 1000);
    setTimeout(() => {
      setMrp("");
      setBatch("");
      setQty("");
      setTotal("");
      setExpiry("");
      setTxt("");
      pfocus.current.focus();
    }, 4000);
  };

  // const handleUpdate = () => {
  //   setUpdate(true);
  //   setTimeout(() => {
  //     refetchproup();
  //   }, 1000);
  //   setTimeout(() => {
  //     setMrp("");
  //     setBatch("");
  //     setQty("");
  //     setTotal("");
  //     setExpiry("");
  //     setTxt("");
  //   }, 4000);
  // };

  const handleDisc = (value) => {
    if (!value) {
      setDisc(0);
    } else {
      var discamt = (subtotal * parseInt(value)) / 100;
      setDisc(discamt);
    }
    setDiscount(value);
  };

  var subtotal = 0;
  products?.sale?.length &&
    products.saleItems.forEach((item) => {
      subtotal += parseInt(item.total);
    });

  const handleSaveAndInvoice = () => {
    refetchupdata();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handlePrint = () => {
    var content = document.getElementById("divcontents");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  console.log(products);
  const handleEdit = (item) => {
    console.log(item);
    setUpdate(!update);
    setProductId(item.product_id);
    setBatch(item.batch);
    var tempExpiry = item.expiry.replace("/", "-");
    setExpiry(item.expiry);
    setMrp(tempExpiry);
    setQty(item.quantity);
    setTotal(item.total);
    setId(item.id);
    setProName(item.name);
  };

  const handleChange = (text) => {
    let textTemp = text;
    if (textTemp[0] !== "1" && textTemp[0] !== "0") {
      textTemp = "";
    }
    if (text.length === 2) {
      if (
        parseInt(textTemp.substring(0, 2)) > 12 ||
        parseInt(textTemp.substring(0, 2)) == 0
      ) {
        textTemp = textTemp[0];
      } else if (text.length === 2 && !backspace) {
        textTemp += "/";
        setBackspace(true);
      } else {
        textTemp = textTemp[0];
        setBackspace(false);
      }
    }
    setExpiry(textTemp);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode == 40) {
      setCursor((prev) => {
        return prev < searchdata?.length ? prev + 1 : 0;
      });
    }
    if (e.keyCode == 38) {
      setCursor((prev) => {
        return prev > 0 ? prev - 1 : searchdata?.length-1;
      });
    }
    if(e.key=='Enter'){
      handleProducts(searchdata[cursor])
    }
  };

  return (
    <div className="mainBody mt-5">
      <div className="innerPageSection">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Counter Sale</h3>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      width: "100%",
                      position: "relative",

                      top: 20,
                    }}
                  >
                    {show ? (
                      <div className="row">
                        <div className="form-group col-md-4">
                          <label>
                            Customer Name :{" "}
                            {data && data?.dataa?.customer[0].fullname}{" "}
                          </label>
                        </div>
                        <div className="form-group col-md-4">
                          <label>
                            Invoice No.:{" "}
                            {data && data.dataa?.sale[0].invoice_no}{" "}
                          </label>
                        </div>
                        <div className="form-group col-md-4">
                          <label>
                            Invoice Date :{" "}
                            {data && data.dataa?.sale[0].invoicedate}{" "}
                          </label>
                        </div>

                        <div className="form-group col-md-4">
                          <label>Product Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={txt}
                            onChange={(e) => setTxt(e.target.value)}
                            ref={pfocus}
                            onKeyDown={(e) => handleKeyDown(e)}
                          />
                          <div className="row" id="products">
                            {searchdata &&
                              searchdata.map((item, index) => {
                                return (
                                  <div key={index} className={cursor==index?'arrowDown':''} >
                                    {item.name}
                                    {"   "}
                                    {item.packing}
                                    <button
                                      onClick={() => handleProducts(item)}
                                    >
                                      add
                                    </button>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        <div className="form-group col-md-2">
                          <label>MRP</label>
                          <input
                            type="number"
                            className="form-control"
                            name="mrp"
                            id="mrp"
                            step="any"
                            value={mrp}
                            onChange={(e) => setMrp(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group col-md-1">
                          <label>Quantity</label>
                          <input
                            type="number"
                            step="any"
                            className="form-control"
                            name="qty"
                            id="qty"
                            onChange={(e) => handleCal(e)}
                            value={qty}
                            required
                          />
                        </div>
                        <div className="form-group col-md-1">
                          <label>Batch No.</label>
                          <input
                            type="text"
                            className="form-control"
                            name="batch"
                            id="batch"
                            value={
                              searchdatadynamic && searchdatadynamic.batch
                                ? searchdatadynamic.batch
                                : 0
                            }
                            onChange={(e) => setBatch(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-1">
                          <label>Expiry</label>
                          <input
                            type="text"
                            className="form-control"
                            name="expiry"
                            id="expiry"
                            maxlength="5"
                            value={
                              searchdatadynamic && searchdatadynamic.expiry
                                ? searchdatadynamic.expiry
                                : 0
                            }
                            onChange={(e) => handleChange(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-1">
                          <label>Total</label>
                          <input
                            type="number"
                            className="form-control"
                            name="total"
                            id="total"
                            value={total}
                            readOnly
                            required
                          />
                        </div>

                        {update ? (
                          <button
                            name="submit"
                            className="btn btn-success btn-success2"
                            style={{
                              width: "50px;",
                            }}
                            // onClick={() => handleUpdate()}
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            name="submit"
                            className="btn btn-success btn-success2 hh"
                            style={{
                              width: "50px;",
                            }}
                            onClick={() => handleSubmit2()}
                          >
                            Add
                          </button>
                        )}

                        <div className="row mt-4">
                          <div className="col-md-12" id="divcontents">
                            <div
                              className="card"
                              id="itemTable"
                              style={{
                                width: "100%",
                                marginTop: "40px;",
                              }}
                            >
                              <div className="card-header">
                                <h3 className="card-title">Purchase Items</h3>
                              </div>
                              <div className="card-body">
                                <table id="" className="tbl">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th>Product Name</th>
                                      <th>Batch</th>
                                      <th>Expiry</th>
                                      <th>Mrp</th>
                                      <th>Qty</th>
                                      <th>Total</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {products &&
                                      products?.sale?.length &&
                                      products?.saleItems.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td width="30px">{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.batch}</td>
                                            <td>{item.expiry}</td>
                                            <td width="120px">{item.price}</td>
                                            <td width="70px">
                                              {item.quantity}
                                            </td>
                                            <td width="120px">{item.total}</td>
                                            <td
                                              width="80px"
                                              className="actionBtn"
                                            >
                                              <a>
                                                <i
                                                  className="fas fa-edit"
                                                  onClick={() =>
                                                    handleEdit(item)
                                                  }
                                                ></i>
                                              </a>
                                              <a>
                                                <i className="fas fa-trash-alt"></i>
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      })}

                                    <tr>
                                      <td
                                        className="labelFont text-start p1"
                                        colspan="6"
                                      >
                                        <b>SubTotal</b>
                                      </td>

                                      <td>
                                        <b>{subtotal}</b>
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="5"
                                        className="labelFont text-start p1"
                                      >
                                        <b>Discount Total</b>
                                      </td>
                                      <td
                                        colspan="1"
                                        className="discountTbl"
                                        width="200px"
                                      >
                                        <input
                                          className="form-control"
                                          type="number"
                                          name="discount"
                                          id="discount"
                                          step="any"
                                          onChange={(e) =>
                                            handleDisc(e.target.value)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <b> {subtotal - disc}</b>
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>

                                <div className="col-md-12 form-group">
                                  <div align="right" className="btn2">
                                    <button
                                      className="mt-3"
                                      onClick={() => handleSaveAndInvoice()}
                                    >
                                      Save & Exit
                                    </button>
                                    <button onClick={() => handlePrint()}>
                                      Save & Print
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <iframe
                          id="ifmcontentstoprint"
                          style={{ display: "none" }}
                        ></iframe>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="form-group col-md-2">
                          <label>Sale Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date"
                            value={moment().format("YYYY-MM-DD")}
                            onChange={(e) => setCdate(e.target.value)}
                            required
                          />
                        </div>

                        <div className="form-group col-md-2">
                          <label>Customer Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="cname"
                            onChange={(e) => setCname(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label>Customer Mobile</label>
                          <input
                            type="number"
                            className="form-control"
                            name="cmobile"
                            onChange={(e) => setCmobile(e.target.value)}
                          />
                        </div>

                        <div className="form-group col-md-2">
                          <label>Invoice No.</label>
                          <input
                            type="text"
                            className="form-control"
                            id="invoice"
                            name="invoice"
                            value={invoice}
                            onChange={(e) => setInvoice(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label>Doctor Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="drName"
                            onChange={(e) => setDrname(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="caddress"
                            onChange={(e) => setAddress(e.target.value)}
                            required
                          />
                        </div>

                        <div className="form-group col-md-1 text-center">
                          <button
                            name="submit"
                            className="btn btn-success m-auto"
                            id="submit"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterSaleAdd;
